import { Router } from 'backbone';

import MainView from './views/MainView';
import AccountPasswordView from './views/account/AccountPasswordView';
import AccountSettingsView from './views/account/AccountSettingsView';
import AccountAlertsView from './views/account/AccountAlertsView';
import AuthenticationView from './views/authentication/AuthenticationView';
import AuthenticationForgotPasswordView from './views/authentication/AuthenticationForgotPasswordView';
import ReportsView from './views/reports/ReportsView';

import LimitTradingView from './views/limitTrading/LimitTradingView';
import ClickToTradeView from './views/clickToTrade/ClickToTradeView';
import RfqTradingView from './views/rfqTrading/RfqTradingView';

import SearchFormView from './views/shared/search/SearchFormView';
import ExecutionsView from './views/executions/ExecutionsView';
import OrdersView from './views/orders/OrdersView';
import QuotesView from './views/quotes/QuotesView';
import AlertsView from './views/alerts/AlertsView';
import StatisticsView from './views/statistics/StatisticsView';
import AuditsView from './views/audits/AuditsView';

import MasterAccountEditView from './views/masteraccount/MasterAccountEditView';

class AppRouter extends Router {
	constructor(options) {
		super(options);

		this.mainView = new MainView({ el: '.js-site-wrapper' }).render();
		this.currentView = null;
	}

	get routes() {
		return {
			'': 'home',

			authentication: 'authentication',
			'authentication/set-password?userToken=:token': 'resetPassword',
			'authentication/forgot-password': 'forgotPassword',

			executions: 'executionsSearch',
			'executions/*path': 'executionsSearch',

			orders: 'ordersSearch',
			'orders/*path': 'ordersSearch',

			quotes: 'quotesSearch',
			'quotes/*path': 'quotesSearch',

			alerts: 'alertsSearch',
			'alerts/search': 'alertsSearch',
			'alerts/searchpage': 'alertsSearch',

			'reports(/:category)(/*period)': 'reports',
			// 'reports': 'reports',

			statistics: 'statisticsSearch',
			'statistics/search': 'statisticsSearch',
			'statistics/searchpage': 'statisticsSearch',

			audits: 'auditsSearch',
			'audits/search': 'auditsSearch',
			'audits/searchpage': 'auditsSearch',

			// eslint-disable-next-line quote-props
			'clicktotrade': 'clickToTrade',
			// eslint-disable-next-line quote-props
			'rfqtrading': 'rfqTrading',
			// eslint-disable-next-line quote-props
			'limittrading': 'limitTrading',

			search: 'search',
			'account/password': 'accountPassword',
			'account/settings': 'settings',
			'account/alerts': 'alerts',

			'masteraccount/add': 'masterAccountEdit',
			'masteraccount/edit/(:id)': 'masterAccountEdit',
		};
	}

	clickToTrade() {
		this.currentView = new ClickToTradeView({ el: '.js-main' }).render();
	}

	limitTrading() {
		this.currentView = new LimitTradingView({ el: '.js-main' }).render();
	}

	rfqTrading() {
		this.currentView = new RfqTradingView({ el: '.js-main' }).render();
	}

	search() {
		this.currentView = new SearchFormView({ el: '.js-search-form' }).render();
	}

	executionsSearch() {
		this.currentView = new ExecutionsView({ el: '.js-main' }).render();
	}

	ordersSearch() {
		this.currentView = new OrdersView({ el: '.js-main' }).render();
	}

	quotesSearch() {
		this.currentView = new QuotesView({ el: '.js-main' }).render();
	}

	alertsSearch() {
		this.currentView = new AlertsView({ el: '.js-main' }).render();
	}

	reports() {
		this.currentView = new ReportsView({ el: '.js-main' }).render();
	}

	statisticsSearch() {
		this.currentView = new StatisticsView({ el: '.js-main' }).render();
	}

	auditsSearch() {
		this.currentView = new AuditsView({ el: '.js-main' }).render();
	}

	resetPassword(token) {
		this.currentView = new AccountPasswordView({ el: '.js-password-form' }).render();
		this.currentView.model.set('userToken', token);
	}

	authentication() {
		this.currentView = new AuthenticationView({ el: '.js-authentication-form' }).render();
	}

	forgotPassword() {
		this.currentView = new AuthenticationForgotPasswordView({ el: '.js-main' }).render();
	}

	// TODO: rename everything to 'account' instead of 'privacy'?
	accountPassword() {
		this.currentView = new AccountPasswordView({ el: '.js-privacy-form' }).render();
	}

	settings() {
		this.currentView = new AccountSettingsView({ el: '.js-settings-form' }).render();
	}

	alerts() {
		this.currentView = new AccountAlertsView({ el: '.js-alerts-form' }).render();
	}

	masterAccountEdit() {
		this.currentView = new MasterAccountEditView({ el: '.js-form' }).render();
	}
}

export default AppRouter;
