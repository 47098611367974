import Cookies from 'js-cookie';
import eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';

const MOBILE_TOGGLE_CLASS = 'nav-mobile-active';
const WIDE_TOGGLE_CLASS = 'nav-wide-collapsed';

class NavigationView extends BaseView {
	ui() {
		return {
			navigationContainerOuter: '.layout-header__navigation',
			navigationContainer: '.js-navigation',
			navMobileToggle: '.js-nav-mobile-toggle',
			navWideToggle: '.js-nav-wide-toggle',
			subToggle: '.js-sub-toggle',
			subMenus: '.js-sub-nav',
		};
	}

	events() {
		return {
			'click @ui.navigationContainerOuter': 'onOutsideClick',
			'click @ui.navMobileToggle': 'onNavMobileToggle',
			'click @ui.navWideToggle': 'onNavWideToggleClick',
			'click @ui.subToggle': 'onSubToggleClick',
		};
	}

	onOutsideClick(event) {
		const [navigationContainer] = this.uiElements.navigationContainerOuter;

		if (navigationContainer.contains(event.target)) {
			return;
		}

		this.closeMenus();
	}

	onNavMobileToggle() {
		this.el.classList.toggle(MOBILE_TOGGLE_CLASS);
	}

	onNavWideToggleClick() {
		this.el.classList.toggle(WIDE_TOGGLE_CLASS);

		if (this.el.classList.contains(WIDE_TOGGLE_CLASS)) {
			Cookies.set('menu-collapsed', 'true');
		} else {
			Cookies.remove('menu-collapsed');
		}

		eventbus.trigger('menu-collapse-toggle');
	}

	onSubToggleClick(e) {
		const target = e.currentTarget;
		const subMenu = target.parentNode.querySelector('.js-sub-nav');
		const isMenuVisible = subMenu.classList.contains('is-active');

		e.stopPropagation();

		this.closeSubMenus();

		if (isMenuVisible) {
			subMenu.classList.remove('is-active');
		} else {
			subMenu.classList.add('is-active');
		}
	}

	closeSubMenus() {
		Array.from(document.querySelectorAll('.js-sub-nav')).forEach((el) => {
			el.classList.remove('is-active');
		});
	}

	closeMenus() {
		this.closeSubMenus();

		this.el.classList.remove(MOBILE_TOGGLE_CLASS);
	}
}

export default NavigationView;
