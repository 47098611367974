import _ from 'underscore';

import ValidationFormView from '../shared/ValidationFormView';
import AccountSettingsModel from '../../models/AccountSettingsModel';

class AccountSettingsView extends ValidationFormView {
	constructor(options) {
		super(options);

		this.model = new AccountSettingsModel();
	}

	events() {
		return _.extend({}, super.events(), {
			'change input[id=preTradeConfirmation]': 'onToggleTradeConfirmation',
		});
	}

	ui() {
		return _.extend({}, super.ui(), {
			confirmationAmount: '.js-confirmation-amount',
		});
	}

	onToggleTradeConfirmation() {
		const [el] = this.uiElements.confirmationAmount;

		el.classList.toggle('is-hidden');
	}
}

export default AccountSettingsView;
