import * as DOM from '../../utils/dom';

import BaseView from '../BaseView';

class AccountAlertsView extends BaseView {
	ui() {
		return {
			inputSelectAll: '.js-select-all',
			inputSelectSingle: '.js-select-single',
		};
	}

	events() {
		return {
			'change @ui.inputSelectAll': 'onSelectAllChange',
			'change @ui.inputSelectSingle': 'onSelectSingleChange',
		};
	}

	constructor(options = {}) {
		super(options);

		this.totalInputs = 0;
	}

	// select, or deselect all checkboxes
	onSelectAllChange(e) {
		const isSelected = e.target.checked;

		this.toggleAll(isSelected);
	}

	// set number of selected inputs in model
	onSelectSingleChange() {
		const numSelected = this.el.querySelectorAll('.js-select-single:checked').length;

		this.uiElements.inputSelectAll[0].checked = numSelected === this.totalInputs;
	}

	toggleAll(isSelected) {
		DOM.forEach(this.uiElements.inputSelectSingle, (input) => {
			// eslint-disable-next-line no-param-reassign
			input.checked = isSelected;
		}, this);
	}

	onAfterRender() {
		this.totalInputs = this.uiElements.inputSelectSingle.length;
	}
}

export default AccountAlertsView;
