import _ from 'underscore';
import ValidationModel from './ValidationModel';

class ClickToTradeFormModel extends ValidationModel {
	defaults() {
		return {
			instrumentIdentifier: null,
			marketmakerId: null,
			volume: null,
		};
	}

	validation() {
		return _.extend({}, super.validation(), {
			// can't be empty
			marketmakerId: 'isNotEmpty',

			// can't be empty, must be an ISIN or WKN
			instrumentIdentifier(value) {
				const isIsin = this.patterns().isin.test(value);
				const isWkn = this.patterns().wkn.test(value);

				return this.helpers().isNotEmpty(value) && (isIsin || isWkn);
			},

			// can't be empty, must be positive digit with a minimum value of 1, maximum value of 99999999
			volume(value) {
				return this.helpers().isNotEmpty(value)
				&& this.helpers().isDigit(value)
				&& this.helpers().isPositiveNumber(value)
				&& parseInt(value, 10) >= 1
				&& parseInt(value, 10) <= 99999999;
			},
		});
	}
}

export default ClickToTradeFormModel;
