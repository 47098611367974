import DatepickerView from '../DatepickerView';
import ValidationFormView from '../ValidationFormView';
import SearchFormModel from '../../../models/SearchFormModel';

// Base view for search filters
// Extends the `ValidationFormView`
// -------------------------------
class SearchFormView extends ValidationFormView {
	constructor(options) {
		if (!options.model) {
			// eslint-disable-next-line no-param-reassign
			options.model = new SearchFormModel();
		}

		super(options);

		// overridable in extending views
		this.datePickers = options.datePickers || {
			from: new DatepickerView({
				el: '.js-date-from',
				picker: { maxDate: new Date() },
			}),
			to: new DatepickerView({
				el: '.js-date-to',
				picker: { maxDate: new Date() },
			}),
		};

		this.listenTo(this.datePickers.from, 'date:select', this.onFromDateSelected);
		this.listenTo(this.datePickers.to, 'date:select', this.onToDateSelected);
	}

	// reset the form to the default
	onFormReset() {
		this.model = new SearchFormModel();
	}

	// can be overridden by extending views
	onAfterRender() {
		this.createFilters();
	}

	createFilters() {
		this.createDatePickers();
	}

	createDatePickers() {
		if (!this.datePickers) {
			return;
		}

		Object.keys(this.datePickers).forEach((key) => {
			this.datePickers[key].render();
		});
	}

	// set model value to formatted date
	// set minimum dateTo to selected (unformatted) date
	onFromDateSelected(date, dateFormatted) {
		this.model.set('dateFrom', dateFormatted);

		this.datePickers.to.picker.set({ minDate: date });
	}

	// set model value to formatted date
	// set maximum dateFrom to selected (unformatted) date
	onToDateSelected(date, dateFormatted) {
		this.model.set('dateTo', dateFormatted);

		this.datePickers.from.picker.set({ maxDate: date });
	}
}

export default SearchFormView;
