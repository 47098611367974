import _ from 'underscore';

import DatepickerView from '../shared/DatepickerView';
import SearchFormView from '../shared/search/SearchFormView';
import QuoteFormModel from '../../models/QuoteFormModel';

class QuotesFormView extends SearchFormView {
	ui() {
		return _.extend({}, super.ui(), {
			timeFrom: '.js-time-from',
			timeTo: '.js-time-to',
		});
	}

	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new QuoteFormModel();
		// eslint-disable-next-line no-param-reassign
		options.datePickers = {
			from: new DatepickerView({
				el: '.js-date-from',
				picker: {
					maxDate: new Date(),
				},
			}),
		};

		super(options);
	}

	// override
	createFilters() {
		// base method call
		this.createDatePickers();
	}

	// gets called in base view but shouldn't do anything here
	setSelectedFromDate() {
		// override
	}
}

export default QuotesFormView;
