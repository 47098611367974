// View Base Methods
// -----------------

// Custom methods with which a `Backbone.View` can be extended
// Adds the UI and UIEvents functionality from Marionette
// as well as onAfterRender, onAfterRemove functions
// Automaticly renders subviews when a view has a `views` object

import { View } from 'backbone';

class BaseView extends View {
	onAfterRender() {
		// overridable
	}

	onBeforeRemove() {
		// overridable
	}

	onAfterRemove() {
		// overridable
	}

	render() {
		this.renderViews();

		this.getUI();

		this.onAfterRender();

		return this;
	}

	remove() {
		this.onBeforeRemove();

		this.removeViews();

		// see: http://backbonejs.org/docs/backbone.html#section-158
		// don't use `Backbone.View.prototype.remove.apply` to prevent inifinte recursion
		this._removeElement();
		this.stopListening();

		this.trigger('remove', this);

		this.onAfterRemove();

		return this;
	}

	// Render all views
	renderViews() {
		const viewKeys = Object.keys(this.views || {});

		viewKeys.forEach((key) => {
			this.views[key].render();
		});
	}

	// Remove all views
	removeViews() {
		Object.keys(this.views || {}).forEach((key) => {
			this.removeView(key);
		});

		this.views = {};
	}

	// remove a single view
	removeView(viewName) {
		if (!this.views[viewName]) {
			return;
		}

		this.views[viewName].remove();

		delete this.views[viewName];
	}

	// Get DOM element by `ui` selector
	// ui() { return { 'pageTitle': 'h1' }; } becomes
	// ui() { return { 'pageTitle': NodeList }; }
	getUI() {
		if (typeof this.ui === 'undefined') {
			return;
		}

		// `this.ui` needs to be a function returning an object
		if (typeof this.ui !== 'function') {
			throw new Error('`this.ui` needs to be a function returning an object');
		}

		const uiElements = Object.entries(this.ui())
			.reduce((memo, [key, selector]) => ({
				...memo,
				[key]: this.el.querySelectorAll(selector),
			}), {});

		this.uiElements = uiElements;
	}

	// Maps the ui() object to the events() object:
	// `ui() { btn: '.my-fancy-button' }` and `events() { 'click @ui.btn': handleClick }`
	// becomes `events() { 'click .my-fancy-button': handleClick }`
	//
	// See: `getUi()`
	// See: [Marionette.normalizeUIString](http://marionettejs.com/annotated-src/backbone.marionette.html#section-89)
	getUIEvents() {
		const uiElements = this.ui
			? this.ui()
			: {};

		// no need to continue without events
		if (typeof this.events === 'undefined') {
			return {};
		}

		// `this.events` needs to be a function returning an object
		if (typeof this.events !== 'function') {
			throw new Error('`this.events` needs to be a function returning an object');
		}

		const events = this.events();

		const uiEvents = Object.keys(events).reduce((memo, currentEvent) => {
			const eventKey = currentEvent
				.replace(/@ui\.[a-zA-Z_$0-9]*/g, (r) => uiElements[r.slice(4)]);

			// eslint-disable-next-line no-param-reassign
			memo[eventKey] = events[currentEvent];

			return memo;
		}, {});

		return uiEvents;
	}

	// https://github.com/jashkenas/backbone/blob/master/backbone.js#L1393
	delegateEvents(events) {
		const uiEvents = this.getUIEvents();
		const combinedEvents = {
			...events,
			...uiEvents,
		};

		super.delegateEvents(combinedEvents);
	}
}

export default BaseView;
