import SearchView from '../shared/search/SearchView';
import QuotesFormView from './QuotesFormView';

class QuotesView extends SearchView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.views = {
			form: new QuotesFormView({ el: '.js-filters' }),
		};

		super(options);
	}
}

export default QuotesView;
