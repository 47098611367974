import Backbone from 'backbone';
import Eventbus from '../utils/eventbus';

import BaseView from './BaseView';
import NavigationView from './shared/NavigationView';
import ModalView from './shared/ModalView';
import LoaderView from './shared/LoaderView';
import InactiveNoticeView from './shared/InactiveNoticeView';

class MainView extends BaseView {
	ui() {
		return {
			ajaxLink: '[data-ajax="true"]',
			btnPrint: '.js-button-print',
		};
	}

	events() {
		return {
			'click @ui.ajaxLink': 'onAjaxLinkClick',
			'click @ui.btnPrint': 'onPrintClick',
		};
	}

	initialize() {
		// catch and bind ajax events
		// see: https://api.jquery.com/category/ajax/global-ajax-event-handlers/
		Backbone.$(document).ajaxStart(this.onAjaxStart.bind(this));
		Backbone.$(document).ajaxComplete(this.onAjaxComplete.bind(this));

		this.listenTo(Eventbus, 'modal:show:view', this.onShowModalView);
		this.listenTo(Eventbus, 'modal:show:html', this.onShowModalHtml);

		// create sub views
		this.views = {
			navigation: new NavigationView({ el: 'body' }),
			modal: new ModalView(),
			loader: new LoaderView(),
			inactiveNotice: new InactiveNoticeView({ el: '.js-inactive-notice' }),
		};
	}

	cloneModalContentWithoutFooter() {
		const contentClone = document.getElementsByClassName('modal__content')[0].cloneNode(true);
		contentClone.getElementsByClassName('modal__footer')[0].remove();

		return contentClone;
	}

	onAfterRender() {
		this.el.appendChild(this.views.modal.el);
		this.el.appendChild(this.views.loader.el);
	}

	// prevents the default behaviour
	// and triggers event with the path
	onAjaxLinkClick(e) {
		const { target } = e;
		let path = target.getAttribute('href');

		// BSCATS-877 - fix IE9 pathname missing leading forward slash
		if (path.charAt(0) !== '/') {
			path = '/'.concat(path);
		}

		e.preventDefault();

		this.views.modal.loadAndShow({
			ajax: { url: path },
			size: target.dataset.size,
		});
	}

	onPrintClick() {
		const printContent = this.cloneModalContentWithoutFooter();

		const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

		winPrint.document.write(printContent.innerHTML);
		winPrint.document.close();
		winPrint.focus();
		winPrint.print();
		winPrint.close();
	}

	onShowModalView(view, modalHasEventListeners = false) {
		this.views.modal.showView(view, modalHasEventListeners);
	}

	onShowModalHtml(html, modalHasEventListeners = false) {
		this.views.modal.showHtml(html, modalHasEventListeners);
	}

	onAjaxStart() {
		Eventbus.trigger('session:reset');

		this.toggleLoader(true);
	}

	onAjaxComplete() {
		this.toggleLoader(false);
	}

	// toggle loader view
	toggleLoader(show) {
		this.views.loader.toggle(show);
	}
}

export default MainView;
