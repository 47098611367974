import _ from 'underscore';

import ValidationFormView from '../shared/ValidationFormView';
import AccountModel from '../../models/AccountModel';

const IP_ID_PREFIX = 'allowedIp';

// maximum characters in an IP address
const IP_LENGTH = 15;

// the maximum string length of all IP addressess combined
const MAX_LENGTH_IPS = 256;
const MAX_IPS = Math.floor(MAX_LENGTH_IPS / IP_LENGTH);

class MasterAccountEditView extends ValidationFormView {
	constructor(options) {
		// eslint-disable-next-line no-param-reassign
		options.model = new AccountModel();

		super(options);

		this.setIpValidations();
	}

	setIpValidations() {
		// execute `querySelectorAll` once
		const { ipFields } = this;

		if (this.numIps > 1) {
			for (let i = 1; i < ipFields.length; i += 1) {
				const field = ipFields[i];
				const fieldId = field.id;

				this.model.set(fieldId, field.value);
				this.model.addIpValidation(fieldId);
			}
		}
	}

	ui() {
		return _.extend({}, super.ui(), {
			ipFieldsContainer: '.js-ip-fields',
			addIpButton: '.js-add-ip',
			removeIpButton: '.js-remove-ip',
		});
	}

	events() {
		return _.extend({}, super.events(), {
			'click @ui.addIpButton': 'onAddIp',
			'click @ui.removeIpButton': 'onRemoveIp',
		});
	}

	// the button bound to this handler is inside a form
	// prevent it from submitting the form
	onAddIp(e) {
		e.preventDefault();

		const fieldId = `${IP_ID_PREFIX}${Date.now()}`;

		this.addIpField(fieldId);
		this.model.addIpValidation(fieldId);
		this.toggleAddIpButton();
	}

	onRemoveIp(e) {
		e.preventDefault();

		const field = e.target.parentElement;

		if (!field) {
			return;
		}

		const fieldId = field.getAttribute('data-id');

		field.parentElement.removeChild(field);

		this.model.removeIpValidation(fieldId);
		this.toggleAddIpButton();
	}

	toggleAddIpButton() {
		const showButton = this.numIpFields < MAX_IPS;
		const [addIpButton] = this.uiElements.addIpButton;

		if (showButton) {
			addIpButton.classList.remove('is-hidden');
		} else {
			addIpButton.classList.add('is-hidden');
		}
	}

	addIpField(fieldId) {
		const div = document.createElement('div');

		div.innerHTML = this.getIpFieldTemplate(fieldId);

		this.uiElements.ipFieldsContainer[0].appendChild(div.firstChild);
	}

	getIpFieldTemplate(fieldId) {
		return `<div class="field__combination trailer-half">
					<input type="text"
						class="field__input"
						name="allowedIps[]"
						id="${fieldId}"
						value=""
						data-validate="${fieldId}"
						data-error="Enter a valid IP">
					<button class="button button--small button--negative field__combination-button js-remove-ip">
						Remove
					</button>
				</div>`;
	}

	get ipFields() {
		return Array.from(this.el.querySelectorAll('input[name="allowedIps[]"]'));
	}

	get numIpFields() {
		return this.ipFields.length;
	}
}

export default MasterAccountEditView;
