import { View } from 'backbone';
import flatpickr from 'flatpickr/dist/flatpickr';

const DATE_FORMAT = 'd-m-Y';

class DatepickerView extends View {
	constructor(options = {}) {
		super(options);

		this.options = options;
	}

	render() {
		this.defaultDate = this.stringToDate(this.el.value);

		// date from picker
		this.picker = flatpickr(this.el, {
			dateFormat: DATE_FORMAT,
			minDate: this.options.picker.minDate,
			maxDate: this.options.picker.maxDate,
			onChange: ([date], dateStr) => this.onSelect(date, dateStr),
		});

		return this;
	}

	getPicker() {
		return this.picker;
	}

	// convert a 'dd-mm-yyyy' string to a date object
	// if that fails, return the current date
	stringToDate(dateString) {
		const dateArr = (dateString || '').split('-');

		// fail, return current date
		if (dateArr.length !== 3) {
			return new Date();
		}

		// months are zero based indexed
		const month = dateArr[1] - 1;

		return new Date(dateArr[2], month, dateArr[0]);
	}

	// needs to be bound to view's scope
	onSelect(date, dateString) {
		this.trigger('date:select', date, dateString);
	}

	reset() {
		this.picker.setDate(this.defaultDate, true);
	}
}

export default DatepickerView;
