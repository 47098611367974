import ValidationFormView from '../shared/ValidationFormView';
import AccountPasswordModel from '../../models/AccountPasswordModel';

class AccountPasswordView extends ValidationFormView {
	constructor(options) {
		// eslint-disable-next-line no-param-reassign
		options.model = new AccountPasswordModel();

		super(options);
	}
}

export default AccountPasswordView;
