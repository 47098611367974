import BaseView from '../BaseView';

class AuthenticationView extends BaseView {
	ui() {
		return {
			usernameInput: '.js-username-input',
			passwordInput: '.js-password-input',
		};
	}

	onAfterRender() {
		this.uiElements.usernameInput[0].value = '';
		this.uiElements.passwordInput[0].value = '';
	}
}

export default AuthenticationView;
