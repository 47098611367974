import moment from 'moment';

import VisualizationFactory from './visualization';
import DefinitionListItemModel from '../models/definition-list-item';
import GroupedVisualizationListModel from '../models/grouped-visualization-list';
import VisualizationListModel from '../models/visualization-list';
import VisualizationModel from '../models/visualization';

import chartSettings from '../config/chart';

export default {
	createAvailableInstrumentsPerDayVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createChartVisualization({
				type: 'bar',
				data: {
					labels: data.map((d) => moment(d.date).format('DD-MM-YYYY')),
					datasets: [{
						backgroundColor: chartSettings.colors[0],
						data: data.map((d) => d.count),
					}],
				},
				options: {
					legend: {
						display: false,
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								suggestedMin: 0,
							},
						}],
					},
				},
			}),
		]);
	},

	createBrokersVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createPersonListVisualization(data),
		]);
	},

	createExecutionsVisualization(sourceList) {
		if (!sourceList) {
			return this._createEmptyState();
		}

		const previousBusinessDate = this
			._getMinDate(sourceList.map((source) => source.previousBusinessDate))
			.format('LL');

		const businessDate = this
			._getMaxDate(sourceList.map((source) => source.businessDate))
			.format('LL');

		return new GroupedVisualizationListModel({
			Amount: new VisualizationListModel([
				VisualizationFactory.createChartVisualization({
					type: 'bar',
					data: {
						datasets: [
							{
								label: previousBusinessDate,
								backgroundColor: chartSettings.colors[1],
								borderWidth: 0,
								data: sourceList.map((source) => source.previousDayExecutionsCount),
							}, {
								label: businessDate,
								backgroundColor: chartSettings.colors[0],
								borderWidth: 0,
								data: sourceList.map((source) => source.todayExecutionsCount),
							},
						],
						labels: sourceList.map((source) => source.systemName),
					},
					options: {
						scales: {
							yAxes: [{
								ticks: {
									beginAtZero: true,
									min: 0,
									suggestedMin: 0,
								},
							}],
						},
					},
				}),
			]),
			Volume: new VisualizationListModel([
				VisualizationFactory.createChartVisualization({
					type: 'bar',
					data: {
						datasets: [
							{
								label: previousBusinessDate,
								backgroundColor: chartSettings.colors[1],
								borderWidth: 0,
								data: sourceList.map((source) => source.previousDayAmount),
							}, {
								label: businessDate,
								backgroundColor: chartSettings.colors[0],
								borderWidth: 0,
								data: sourceList.map((source) => source.todayAmount),
							},
						],
						labels: sourceList.map((source) => source.systemName),
					},
					options: {
						scales: {
							yAxes: [{
								ticks: {
									beginAtZero: true,
									min: 0,
									suggestedMin: 0,
									callback(value) {
										return value.toLocaleString();
									},
								},
							}],
						},
						tooltips: {
							callbacks: {
								label(tooltipItem, object) {
									const dataset = object.datasets[tooltipItem.datasetIndex];
									const value = dataset.data[tooltipItem.index];

									return value.toLocaleString();
								},
							},
						},
					},
				}),
			]),
		});
	},

	createInstrumentPeakVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationModel.fromString(`<div class="table-container table-container--compact">
				<table class="table">
					<thead class="table__header">
						<tr class="table__row">
							<th class="table__cell">Time</th>
							<th class="table__cell">ISIN</th>
							<th class="table__cell">Description</th>
							<th class="table__cell">Update rate</th>
						</tr>
					</thead>
					<tbody class="table__body">
						${data.map(({
		description, isin, time, updateRate,
	}) => `<tr class="table__row">
							<td class="table__cell">${time}</td>
							<td class="table__cell">${isin}</td>
							<td class="table__cell">${description}</td>
							<td class="table__cell">${updateRate}</td>
						</tr>`).join('')}
					</tbody>
				</table>
			</div>`),
		]);
	},

	createMarketMakersVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createPersonListVisualization(data),
		]);
	},

	createO256Visualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createDefinitionListVisualization([
				new DefinitionListItemModel('Total', data.quantities.total),
				new DefinitionListItemModel('Buy', data.quantities.buy),
				new DefinitionListItemModel('Sell', data.quantities.sell),
			]),
			VisualizationFactory.createDistributionChartVisualization({
				title: 'Distribution of executions per order type',
				labels: data.distribution.map((d) => d.title),
				datasets: [data.distribution.map((d) => d.value)],
			}),
		]);
	},

	createOrdersVisualization(orders) {
		if (!orders) {
			return this._createEmptyState();
		}

		const openOrders = orders.open.url === ''
			? `<p>${orders.open.count}</p>`
			: `<a href="${orders.open.url}">${orders.open.count}</a>`;

		const rejectedOrders = orders.rejected.url === ''
			? `<p>${orders.rejected.count}</p>`
			: `<a href="${orders.rejected.url}">${orders.rejected.count}</a>`;

		const executedOrders = orders.executions.url === ''
			? `<p>${orders.executions.count}</p>`
			: `<a href="${orders.executions.url}">${orders.executions.count}</a>`;

		return new VisualizationListModel([
			VisualizationFactory.createDefinitionListVisualization([
				new DefinitionListItemModel('Open', openOrders),
				new DefinitionListItemModel('Rejected', rejectedOrders),
				new DefinitionListItemModel('Executed', executedOrders),
			], {
				isVertical: true,
			}),
		]);
	},

	createP1Visualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createDefinitionListVisualization([
				new DefinitionListItemModel('Average', `${data.executionTimes.average}ms`),
				new DefinitionListItemModel('Min', `${data.executionTimes.min}ms`),
				new DefinitionListItemModel('Max', `${data.executionTimes.max}ms`),
			]),
			VisualizationFactory.createDistributionChartVisualization({
				title: 'Distribution of trade execution times',
				labels: data.executionTimes.distribution.map((d) => d.title),
				datasets: [
					data.executionTimes.distribution.map((d) => ({
						label: `${d.value} (${(100 * d.percentage).toFixed(2)}%)`,
						y: d.value,
					})),
				],
				tooltips: {
					callbacks: {
						label(tooltipItem, object) {
							const dataset = object.datasets[tooltipItem.datasetIndex];
							const entry = dataset.data[tooltipItem.index];

							return entry.label;
						},
					},
				},
			}),
		]);
	},

	createP8Visualization(dataGrouped) {
		if (!dataGrouped) {
			return this._createEmptyState();
		}

		function createVisualizationList(data) {
			return new VisualizationListModel([
				VisualizationFactory.createDefinitionListVisualization([
					new DefinitionListItemModel('Average', `${data.receiveTimes.average}ms`),
					new DefinitionListItemModel('Min', `${data.receiveTimes.min}ms`),
					new DefinitionListItemModel('Max', `${data.receiveTimes.max}ms`),
				]),
				VisualizationFactory.createDistributionChartVisualization({
					title: 'Distribution of quote receive times',
					labels: data.receiveTimes.distribution.map((d) => d.title),
					datasets: [data.receiveTimes.distribution.map((d) => d.value)],
				}),
				VisualizationFactory.createDefinitionListVisualization([
					new DefinitionListItemModel('Average', `${data.executionTimes.average}ms`),
					new DefinitionListItemModel('Min', `${data.executionTimes.min}ms`),
					new DefinitionListItemModel('Max', `${data.executionTimes.max}ms`),
				]),
				VisualizationFactory.createDistributionChartVisualization({
					title: 'Distribution of quote execution times',
					labels: data.executionTimes.distribution.map((d) => d.title),
					datasets: [data.executionTimes.distribution.map((d) => d.value)],
				}),
			]);
		}

		const keyNameMapping = {
			all: 'All',
			marketMaker: 'Market maker',
		};

		return new GroupedVisualizationListModel(Object.entries(dataGrouped)
			.reduce((obj, [key, data]) => {
				const name = keyNameMapping[key] || key;

				// eslint-disable-next-line no-param-reassign
				obj[name] = createVisualizationList(data);

				return obj;
			}, {}));
	},

	createP16Visualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel(Object.keys(data).map((type) => {
			const properties = data[type];
			const typeString = type.substring(0, 1).toUpperCase() + type.substring(1);
			const quantity = properties.executions + properties.rejects;

			return VisualizationFactory.createChartVisualization({
				type: 'doughnut',
				data: {
					labels: [
						`Execution count (${properties.executions})`,
						`Reject count (${properties.rejects})`,
					],
					datasets: [{
						backgroundColor: chartSettings.colors,
						borderWidth: 0,
						data: [properties.executions, properties.rejects],
					}],
				},
				options: {
					scales: {
						xAxes: [{
							display: false,
							stacked: true,
							ticks: {
								max: quantity,
							},
						}],
						yAxes: [{
							display: false,
							stacked: true,
							ticks: {
								max: quantity,
							},
						}],
					},
					title: {
						display: true,
						text: `${typeString} (${properties.ratio}%)`,
					},
				},
			});
		}));
	},

	createP512Visualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationModel.fromString(`<div class="card__body">
				<div class="table-container">
					<table class="table">
						<thead class="table__header">
							<tr class="table__row">
								<th class="table__cell">Currency</th>
								<th class="table__cell text-justify-end">Total</th>
								<th class="table__cell text-justify-end">Average</th>
								<th class="table__cell text-justify-end">Min</th>
								<th class="table__cell text-justify-end">Max</th>
							</tr>
						</thead>
						<tbody>
							${data.map((currency) => `<tr class="table__row">
								<td class="table__cell">${currency.currency}</td>
								<td class="table__cell text-justify-end">${currency.amount.total}</td>
								<td class="table__cell text-justify-end">${currency.amount.average}</td>
								<td class="table__cell text-justify-end">${currency.amount.min}</td>
								<td class="table__cell text-justify-end">${currency.amount.max}</td>
							</tr>`).join('\n')}
						</tbody>
					</table>
				</div>
			</div>`),

			VisualizationFactory.createDistributionChartVisualization({
				title: 'Distribution of trade volumes',
				labels: data.length
					? data[0].amountDistribution.map((d) => d.title)
					: [''],
				datasets: data.map((currency) => ({
					label: currency.currency,
					data: currency.amountDistribution.map((d) => d.value),
				})),
				showLegend: true,
			}),
		]);
	},

	createPricesPerDayVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		return new VisualizationListModel([
			VisualizationFactory.createChartVisualization({
				type: 'bar',
				data: {
					labels: data.map((d) => moment(d.date).format('DD-MM-YYYY')),
					datasets: [{
						backgroundColor: chartSettings.colors[0],
						data: data.map((d) => d.count),
					}],
				},
				options: {
					legend: {
						display: false,
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								suggestedMin: 0,
							},
						}],
					},
				},
			}),
		]);
	},

	createTradeExecutionsVisualization(data) {
		if (!data) {
			return this._createEmptyState();
		}

		const lastBusinessDay = moment(data.lastBusinessDay).format('DD MMM, YYYY');
		const indicatorClass = this
			._getPerformanceIndicatorClass(data.performance.executionCountChange);
		const indicatorSymbol = this
			._getPerformanceIndicatorSymbol(data.performance.executionCountChange);

		const tableBody = Object.entries(data.performancePerBroker)
			.map(([broker, dataBroker]) => {
				const brokerIndicatorClass = this
					._getPerformanceIndicatorClass(dataBroker.executionCountChange);
				const brokerIndicatorSymbol = this
					._getPerformanceIndicatorSymbol(dataBroker.executionCountChange);

				return `<tr class="table__row">
					<td class="table__cell">${broker}</td>
					<td class="table__cell text-no-wrap">
						<b>${dataBroker.executionCountToday}</b>
						<span class="indicator ${brokerIndicatorClass}">
							${dataBroker.executionCountChangeFormatted}
							(${dataBroker.executionCountChangePerformanceFormatted})
							${brokerIndicatorSymbol}
						</span>
					</td>
				</tr>`;
			})
			.join('');

		return new VisualizationListModel([
			VisualizationModel.fromString(`
				<div class="trailer-half">
					<span class="dashboard-panel__figure">${data.performance.executionCountToday}</span>
					<span class="indicator ${indicatorClass}">
						${data.performance.executionCountChangeFormatted}
						(${data.performance.executionCountChangePerformanceFormatted})
						${indicatorSymbol}
					</span>
					<div class="is-subjacent">${lastBusinessDay}</div>
				</div>`),
			VisualizationModel.fromString('<h3 class="dashboard-panel__title">Per broker</h3>'),
			VisualizationModel.fromString(`
				<div class="table-container table-container--compact">
					<table class="table">
						<thead class="table__header">
							<tr class="table__row">
								<th class="table__cell">Broker</th>
								<th class="table__cell" colspan="2">Performance</th>
							</tr>
						</thead>
						<tbody>${tableBody}</tbody>
					</table>
				</div>
			</div>`),
		]);
	},

	_createEmptyState() {
		return new VisualizationListModel([
			VisualizationFactory.createEmptyState(),
		]);
	},

	_getMaxDate(dateList) {
		return dateList
			.filter((date) => date !== null)
			.reduce((dateMin, date) => moment.max(dateMin, moment(date)), moment(0));
	},

	_getMinDate(dateList) {
		return dateList
			.filter((date) => date !== null)
			.reduce((dateMin, date) => moment.min(dateMin, moment(date)), moment());
	},

	_getPerformanceIndicatorClass(delta) {
		if (delta > 0) {
			return 'indicator--positive';
		}

		if (delta < 0) {
			return 'indicator--negative';
		}

		return null;
	},

	_getPerformanceIndicatorSymbol(delta) {
		if (delta > 0) {
			return '↑';
		}

		if (delta < 0) {
			return '↓';
		}

		return '';
	},
};
