class ModuleLoader {
	constructor(data = {}, el = document.body, selector = '[data-module]') {
		this.data = data;
		this.el = el;
		this.selector = selector;
	}

	load() {
		const { data } = this;
		const els = Array.from(this.el.querySelectorAll(this.selector));

		// FIXME, make more robust
		const attribute = this.selector.substring(1, this.selector.length - 1);

		els.forEach((el) => {
			const path = el.getAttribute(attribute).split('/');
			const Module = this.getModuleClass(data, path);

			if (Module) { // eslint-disable-line no-underscore-dangle
				new Module(el).start();
			}
		});
	}

	getModuleClass(modules, path) {
		// eslint-disable-next-line no-unreachable-loop
		while (modules && path.length > 1) {
			const name = path.shift();

			// eslint-disable-next-line no-param-reassign
			modules = modules[name];

			return this.getModuleClass(modules, path);
		}

		return modules[path];
	}
}

export default ModuleLoader;
