import Eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';

class LimitTradingResponseView extends BaseView {
	ui() {
		return {
			btnCancel: '.js-button-cancel',
		};
	}

	events() {
		return {
			'submit form': 'onFormSubmit',
			'click @ui.btnCancel': 'onCancelClick',
		};
	}

	onFormSubmit(e) {
		e.preventDefault();

		Eventbus.trigger('form:submit', e.target);
	}

	onCancelClick(e) {
		Eventbus.trigger('form:confirm:cancel', e.target);

		e.preventDefault();
	}

	render(html) {
		this.el.innerHTML = html;

		super.render();

		return this;
	}
}

export default LimitTradingResponseView;
