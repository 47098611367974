import BaseView from '../BaseView';

class ReportsView extends BaseView {
	ui() {
		return {
			inputPeriodSelect: '.js-select-period',
			inputMarketMakerSelect: '.js-select-market-maker',
		};
	}

	events() {
		return {
			'change @ui.inputPeriodSelect': 'onChangePeriod',
			'change @ui.inputMarketMakerSelect': 'onChangeMarketMaker',
		};
	}

	constructor(options = {}) {
		super(options);
	}

	onChangePeriod(e) {
		const { value } = e.target;

		if (value) {
			window.location = value;
		}
	}

	onChangeMarketMaker(e) {
		const { value } = e.target;
		const { pathname } = window.location;
		const location = `${pathname}?mms=${value}`;

		if (value) {
			window.location = location;
		} else {
			window.location = pathname;
		}
	}
}

export default ReportsView;
