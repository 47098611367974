import RfqTradingResponseView from './RfqTradingResponseView';
import RfqTradingQuoteModel from '../../models/RfqTradingQuoteModel';
import template from '../../../../../Views/RfqTrading/QuoteDetail.mustache';

class RfqTradingResponseDetailView extends RfqTradingResponseView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = options.model || new RfqTradingQuoteModel();

		super(options);

		this.template = template;
	}
}

export default RfqTradingResponseDetailView;
