import BaseView from '../BaseView';
import validationFeedback from '../../utils/validationFeedbackUtils';
import ValidationModel from '../../models/ValidationModel';

const VALIDATION_ATTRIBUTE = 'data-validate';

class ValidationFormView extends BaseView {
	constructor(options) {
		super(options);

		this.model = this.model || new ValidationModel();

		this.prefillModel();
	}

	ui() {
		return {
			btnSubmit: 'button[type=submit]',
		};
	}

	events() {
		return {
			// this removes characters, should be done before validation
			'input input[name=instrumentIdentifier]': 'onInstrumentIdentifierInput',
			'input input[data-validate]:not([type=checkbox]):not([type=radio])': 'onValidationFieldChange', // eslint-disable-line max-len
			'keydown input[name=timeFrom]': 'onTimeInput',
			'keydown input[name=timeTo]': 'onTimeInput',
			'change select[data-validate]': 'onValidationFieldChange',
			'change [data-validate][type=checkbox]': 'onValidationFieldChange',
			'change [data-validate][type=radio]': 'onValidationFieldChange',
			submit: 'onFormSubmit',
			reset: 'onFormReset',
		};
	}

	// prefill the model with values from input fields
	// attribute name in model must be the name of the input field
	prefillModel() {
		Object.keys(this.model.attributes).forEach((fieldName) => {
			const field = this.getField(fieldName);

			if (!field) {
				return;
			}

			const isCheckable = field && (field.type === 'checkbox' || field.type === 'radio');

			if (isCheckable && field.checked) {
				this.model.set(fieldName, field.value);
			} else if (!isCheckable && field.value) {
				this.model.set(fieldName, field.value);
			}
		});
	}

	// form submit handler
	// can be overridden in extending views
	onFormSubmit(e) {
		const invalidAttributes = this.validate(true);

		if (invalidAttributes.length !== 0) {
			e.preventDefault();
		}
	}

	onFormReset(e) {
		e.preventDefault();

		// give the browser time to reset the form natively
		setTimeout(() => {
			this.resetForm();
		}, 50);
	}

	// overridable
	// resets the form values to the default model values
	// setting the model values back to its defaults
	// overriding the default browser behaviour after several requests
	resetForm() {
		const defaults = this.model.defaults();

		this.hideValidationFeedback();
		this.resetFields(defaults);
		this.model.set(defaults);
	}

	resetFields(defaults) {
		Object.keys(defaults).forEach((modelAttribute) => {
			const fields = this.getResetFields(modelAttribute);
			const value = defaults[modelAttribute];

			fields.forEach((field) => {
				if (field.type === 'select-one') {
					this.setSelectValue(field, value);
				} else if (field.type === 'checkbox') {
					this.setCheckboxState(field, value);
				} else {
					// eslint-disable-next-line no-param-reassign
					field.value = value;
				}
			});
		});
	}

	setSelectValue(field, value) {
		const selectedOption = field.querySelector('option[selected]');
		let selectOption;

		if (selectedOption) {
			selectedOption.selected = false;
		}

		if (value === null || value === '') {
			// eslint-disable-next-line no-param-reassign
			field.selectedIndex = 0;
		} else {
			selectOption = field.querySelector(`option[value="${value}"]`);
			selectOption.selected = true;
		}
	}

	// TODO: if the value is just a string or null
	// then you can set the value like this: field.checked = !!value;
	setCheckboxState(field, value) {
		if (value === '' || value === null) {
			// eslint-disable-next-line no-param-reassign
			field.checked = false;
		} else if (field.value === value) {
			// eslint-disable-next-line no-param-reassign
			field.checked = true;
		}
	}

	onInstrumentIdentifierInput(e) {
		const field = e.currentTarget;
		let selection = field.selectionStart;
		const regex = /[^a-z0-9]/gi;
		let { value } = field;

		if (regex.test(value)) {
			value = value.replace(regex, '');
			// eslint-disable-next-line no-plusplus
			selection--;
		}

		value = value.toUpperCase();
		field.value = value;

		field.setSelectionRange(selection, selection);
	}

	// https://jira.vicompany.nl/browse/BSCATS-951
	// https://jira.vicompany.nl/browse/BSCATS-1000
	onTimeInput(e) {
		const { key } = e;
		const allowed = [
			'0',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			':',
			'Backspace',
			'Tab',
			'Escape',
			'End',
			'Home',
			'ArrowRight',
			'Right',
			'ArrowLeft',
			'Left',
			'Del',
			'Delete',
		];

		if (allowed.indexOf(key) !== -1) {
			return true;
		}

		e.preventDefault();

		return false;
	}

	// field blur handler
	onValidationFieldChange(e) {
		const field = e.currentTarget;

		this.validateField(field);
	}

	// validate a field
	validateField(field, preventFormValidation) {
		const value = this.getValue(field);
		const modelProperty = field.getAttribute(VALIDATION_ATTRIBUTE);
		const isValid = this.model.preValidate(modelProperty, value);

		this.onFieldValidated(field, isValid);

		if (!preventFormValidation) {
			this.validate(false);
		}

		return isValid;
	}

	validate(showFeedback) {
		const invalidAttributes = this.model.validate();

		if (showFeedback && invalidAttributes !== true) {
			// eslint-disable-next-line no-restricted-syntax
			for (const modelAttribute of invalidAttributes) {
				this.toggleValidation(this.getField(modelAttribute));
			}
		}

		return invalidAttributes;
	}

	getValue(field) {
		return field.type === 'checkbox'
			? this.getCheckboxValues(field)
			: this.getFieldValue(field);
	}

	getField(modelAttribute) {
		return this.el.querySelector(`[${VALIDATION_ATTRIBUTE}="${modelAttribute}"]`);
	}

	// Get fields based on the [data-validation] and
	// on the [name] attribute for checkboxes and radios.
	// TODO: using the [name] attribute which is Harry Harcoded value for checkboxes is a Code Smell!
	getResetFields(modelAttribute) {
		return Array.from(this.el.querySelectorAll(`[${VALIDATION_ATTRIBUTE}="${modelAttribute}"], [name="${modelAttribute}"]`));
	}

	// overridable
	getFieldValue(field) {
		return field.value;
	}

	getCheckboxValues(field) {
		const fieldName = field.name;
		// TODO: looks quite similar to this.getResetFields() and
		// shouldn't you have to use the 'field' (parent) as the selector root (field.querySelectorAll())?
		const elements = Array.from(this.el.querySelectorAll(`input[${VALIDATION_ATTRIBUTE}="${fieldName}"]`));

		return elements.reduce((memo, el) => {
			if (el.checked) {
				memo.push(el.value);
			}

			return memo;
		}, []);
	}

	// gets executed when a field is validated
	onFieldValidated(field, isValid) {
		this.toggleValidation(field, isValid);
	}

	toggleValidation(field, isValid) {
		validationFeedback.toggle(field, isValid);
	}

	hideValidationFeedback() {
		const elements = document.querySelectorAll('.js-form-validation');
		let numElements = elements.length;

		// eslint-disable-next-line no-plusplus
		while (numElements--) {
			elements[numElements].classList.add('is-hidden');
		}
	}
}

export default ValidationFormView;
