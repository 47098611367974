import SearchResultsView from '../shared/search/SearchResultsView';
import OrderResultModel from '../../models/OrderResultModel';

class OrdersResultView extends SearchResultsView {
	initialize() {
		this.model = new OrderResultModel();

		super.initialize();
	}
}

export default OrdersResultView;
