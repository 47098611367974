import Eventbus from '../../utils/eventbus';
import SocketEvents from '../../events/socket';

import BaseView from '../BaseView';
import ProductModel from '../../models/ClickToTradeProductModel';

class ClickToTradeResponseView extends BaseView {
	ui() {
		return {
			bid: '.js-bid',
			ask: '.js-ask',
			priceBuy: '.js-price-buy',
			priceSell: '.js-price-sell',
			form: '.js-form',
			trade: '.js-trade',
		};
	}

	events() {
		return {
			'submit form': 'onFormSubmit',
		};
	}

	get isTradeView() {
		return this.uiElements.trade && this.uiElements.trade.length;
	}

	get tickData() {
		return [
			[this.model.get('instrumentIdentifier'), this.model.get('marketMakerId')],
		];
	}

	constructor(options) {
		super(options);

		this.listenTo(Eventbus, 'modal:hidden', this.unsubscribe.bind(this));
	}

	onAfterRender() {
		if (!this.isTradeView) {
			return;
		}

		this.listenTo(Eventbus, SocketEvents.TICK, this.onTick);

		const formData = new FormData(this.uiElements.form[0]);
		const data = Object.fromEntries(formData);

		this.model = new ProductModel(data);

		this.subscribe();
	}

	subscribe() {
		Eventbus.trigger(SocketEvents.SUBSCRIBE, this.tickData);
	}

	unsubscribe() {
		if (!this.model) {
			return;
		}

		Eventbus.trigger(SocketEvents.UNSUBSCRIBE, this.tickData);
	}

	onFormSubmit(e) {
		e.preventDefault();

		Eventbus.trigger('form:submit', e.target);

		if (!this.isTradeView) {
			return;
		}

		this.unsubscribe();
	}

	onTick(tick) {
		const { lastAsk: [, bid], lastBid: [, ask] } = tick;

		const minimumPrecision = 2;
		const maximumPrecision = 4;

		const buttons = document.querySelectorAll('.no-quote');
		const noQuoteNotice = document.querySelector('.modal__notice');

		buttons.forEach((button) => {
			button.classList.remove('no-quote');
		});

		noQuoteNotice.style.display = 'none';

		this.uiElements.priceBuy[0].value = ask;
		this.uiElements.ask[0].innerText = ask.toLocaleString(undefined, {
			minimumFractionDigits: minimumPrecision,
			maximumFractionDigits: maximumPrecision,
		});

		this.uiElements.priceSell[0].value = bid;
		this.uiElements.bid[0].innerText = bid.toLocaleString(undefined, {
			minimumFractionDigits: minimumPrecision,
			maximumFractionDigits: maximumPrecision,
		});
	}

	render(html) {
		this.el.innerHTML = html;

		super.render();

		return this;
	}
}

export default ClickToTradeResponseView;
