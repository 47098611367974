import _ from 'underscore';
import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const dateFormatted = moment().format('DD-MM-YYYY');
const timeFrom = moment()
	.subtract({ hours: 1 })
	.format('HH:mm:ss');

class QuoteFormModel extends SearchFormModel {
	defaults() {
		return {
			brokerId: '',
			catsEndUserId: '',
			dateFrom: dateFormatted,
			instrumentIdentifier: null,
			marketmakerId: null,
			procStatus: '',
			quantity: null,
			rejectReasonId: '',
			resultsPerPage: 25,
			systemName: '',
			timeFrom,
			timeTo: '23:59:59',
			page: 1,
		};
	}

	validation() {
		return _.extend({}, super.validation(), {
			dateTo() {
				return true;
			},
		});
	}
}

export default QuoteFormModel;
