import BaseView from '../BaseView';

// all this does is hiding a message whenever someone clicks on 'submit'
// https://jira.vicompany.nl/browse/BSCATS-905
class AuthenticationForgotPasswordView extends BaseView {
	events() {
		return {
			'click .js-submit': 'onSubmitClick',
		};
	}

	onSubmitClick() {
		// not placed in `ui` since it can be removed
		const notification = this.el.querySelector('.js-notification');

		if (notification) {
			notification.parentNode.removeChild(notification);
		}
	}
}

export default AuthenticationForgotPasswordView;
