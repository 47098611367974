import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const dateFormatted = moment().format('DD-MM-YYYY');

class AuditFormModel extends SearchFormModel {
	defaults() {
		return {
			RequestId: '',
			dateFrom: dateFormatted,
			dateTo: dateFormatted,
			resultsPerPage: 25,
			timeFrom: '00:00:00',
			timeTo: '00:00:00',
			page: 1,
		};
	}
}

export default AuditFormModel;
