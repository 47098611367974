import * as DOM from '../../../utils/dom';

import BaseView from '../../BaseView';
import SearchResultsModel from '../../../models/SearchResultModel';

class SearchResultsView extends BaseView {
	ui() {
		return {
			inputSelectAll: '.js-select-all',
			inputSelectSingle: '.js-select-single',
		};
	}

	events() {
		return {
			'change @ui.inputSelectAll': 'onSelectAllChange',
			'change @ui.inputSelectSingle': 'onSelectSingleChange',
		};
	}

	initialize() {
		this.model = this.model || new SearchResultsModel();

		this.listenTo(this.model, 'change:allSelected', this.onAllSelectedChange);
	}

	// select, or deselect all checkboxes
	onSelectAllChange(e) {
		const isSelected = e.target.checked;

		this.toggleAll(isSelected);

		// trigger update
		this.updateNumSelected();
	}

	// set number of selected inputs in model
	onSelectSingleChange() {
		this.updateNumSelected();
	}

	toggleAll(isSelected) {
		DOM.forEach(this.uiElements.inputSelectSingle, (input) => {
			// eslint-disable-next-line no-param-reassign
			input.checked = isSelected;
		}, this);
	}

	updateNumSelected() {
		const numSelected = this.el.querySelectorAll('.js-select-single:checked').length;

		this.model.set('numSelected', numSelected);
	}

	onAllSelectedChange(model, allSelected) {
		this.uiElements.inputSelectAll[0].checked = allSelected;
	}

	onAfterRender() {
		this.model.set('totalInputs', this.uiElements.inputSelectSingle.length);
	}
}

export default SearchResultsView;
