import Cookies from 'js-cookie';

const CLASS_CLOSED = 'is-closed';

class PanelToggle {
	constructor(el) {
		this.el = el;
	}

	start() {
		this.btnToggle = this.el.querySelector('.js-toggle');

		this.btnToggle.addEventListener('click', () => this.toggleSidebar());
		window.addEventListener('keydown', (e) => this.onKeyDown(e));
	}

	onKeyDown(e) {
		// e.keyCode is for IE11
		const isSlashKeyPressed = e.key === '/' || e.keyCode === 191;

		if (isSlashKeyPressed && e.ctrlKey) {
			this.toggleSidebar();
		}
	}

	toggleSidebar() {
		this.el.classList.toggle(CLASS_CLOSED);

		if (this.el.classList.contains(CLASS_CLOSED)) {
			Cookies.set('filters-collapsed', 'true');
		} else {
			Cookies.remove('filters-collapsed');
		}
	}
}

export default PanelToggle;
