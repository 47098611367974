import VisualizationListModel from './visualization-list';

export default class GroupedVisualizationListModel {
	constructor(visualizationListMap = {}) {
		Object.values(visualizationListMap).forEach((visualizationList) => {
			if (!(visualizationList instanceof VisualizationListModel)) {
				throw new Error(`VisualizationList "${visualizationList}" is not a VisualizationList`);
			}
		});

		this._visualizationListMap = visualizationListMap;
	}

	getCharts() {
		return Object.values(this._visualizationListMap)
			.reduce((charts, visualizationList) => charts
				.concat(visualizationList.getCharts()), []);
	}

	getElements() {
		return Object.values(this._visualizationListMap)
			.reduce((elements, visualizationList) => elements
				.concat(visualizationList.getElements()), []);
	}

	getElementsPerGroup() {
		return Object.entries(this._visualizationListMap)
			.reduce((result, [group, visualizationList]) => {
				// eslint-disable-next-line no-param-reassign
				result[group] = visualizationList.getElements();

				return result;
			}, {});
	}

	getGroups() {
		return Object.keys(this._visualizationListMap);
	}
}
