import SearchFormView from '../shared/search/SearchFormView';
import AlertFormModel from '../../models/AlertFormModel';

class AlertsFormView extends SearchFormView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new AlertFormModel();

		super(options);
	}
}

export default AlertsFormView;
