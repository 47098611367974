// Toggles a feedback view and creates one if needed
// -------------------------------------------------

const validationFeedback = {
	// show or hide a feedback element
	toggle(formField, isValid) {
		let errorMsg;
		let view;

		if (formField) {
			// get the error message and view to show
			errorMsg = formField.getAttribute('data-error') || 'invalid input';
			view = this.getFeedbackElement(formField, isValid, errorMsg);

			if (isValid && view) {
				// valid, hide if existing
				view.classList.add('is-hidden');
			} else if (!isValid && view) {
				// invalid, show it
				view.classList.remove('is-hidden');
			}
		}
	},

	// get the feedback element or create one if needed
	getFeedbackElement(fieldEl, isValid, errorMsg) {
		let referenceNode = fieldEl.parentNode;
		const insertAfterNode = fieldEl.nextSibling;
		let id = `${fieldEl.id}-feedback`;
		let el;

		if (referenceNode.tagName === 'SELECT') {
			referenceNode = referenceNode.parentNode;
			id = `${referenceNode.id}-feedback`;
		}

		el = document.getElementById(id);

		if (!el && !isValid) {
			// create new element
			el = this.createFeedbackElement(id, errorMsg);

			if (fieldEl.type === 'checkbox' || fieldEl.type === 'radio') {
				referenceNode.appendChild(el);
			} else {
				referenceNode.insertBefore(el, insertAfterNode);
			}
		}

		return el;
	},

	// create and return a new feedback element
	createFeedbackElement(id, message) {
		const el = document.createElement('div');

		el.setAttribute('id', id);
		el.classList.add('field__validation', 'js-form-validation');

		el.innerHTML = message;

		return el;
	},
};

export default validationFeedback;
