import SearchFormView from '../shared/search/SearchFormView';
import AuditFormModel from '../../models/AuditFormModel';

class AuditsFormView extends SearchFormView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new AuditFormModel();

		super(options);
	}
}

export default AuditsFormView;
