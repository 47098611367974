import _ from 'underscore';

import SearchFormView from '../shared/search/SearchFormView';
import ExecutionFormModel from '../../models/ExecutionFormModel';

class ExecutionsFormView extends SearchFormView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new ExecutionFormModel();

		super(options);
	}

	ui() {
		return _.extend({}, super.ui(), {
			compareCheckboxes: '.js-checkbox-compare',
			executionPriceField: '.js-execution-price',
		});
	}

	events() {
		return _.extend({}, super.events(), {
			'change @ui.compareCheckboxes': 'onCompareCheckboxChange',
		});
	}

	// base override
	onAfterRender() {
		super.onAfterRender();

		this.togglePriceIsActive();
	}

	// 3 checkboxes: lessThan, equalsTo, greaterThan
	// lessThan and greaterThan can't be both checked
	// only allowed selections: >, <, =, <=, >=
	onCompareCheckboxChange(e) {
		const checkboxName = e.target.name;
		const { checked } = e.target;
		let uncheckName = '';

		if (checked) {
			if (checkboxName === 'lessThan') {
				uncheckName = 'greaterThan';
			} else if (checkboxName === 'greaterThan') {
				uncheckName = 'lessThan';
			}

			if (uncheckName) {
				this.el.querySelector(`input[name=${uncheckName}]`).checked = false;
			}
		}

		this.togglePriceIsActive();
	}

	togglePriceIsActive() {
		const checkboxes = Array.from(this.uiElements.compareCheckboxes);
		const isActive = checkboxes.some((cb) => cb.checked);

		const [field] = this.uiElements.executionPriceField;

		field.required = isActive;
		field.disabled = !isActive;
	}
}

export default ExecutionsFormView;
