import _ from 'underscore';
import { Model } from 'backbone';

class ValidationModel extends Model {
	// returns default model attributes to validate
	validation() {
		return {};
	}

	// add a `patters` object to the model
	patterns() {
		return {
			alphanumeric: /^[a-z0-9]+$/i,
			// alphanumeric but spaces also allowed
			alphanumericSpace: /^[a-z0-9 ]+$/i,
			email: /^[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i, // eslint-disable-line max-len
			// EUR, USD, PHP, etc
			currency: /^([A-Z]){3}$/,

			// dd-mm-yyyy
			date: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,

			// VI0000000000
			isin: /[A-Z]{2}([A-Z0-9]){10}/,

			// https://jira.vicompany.nl/browse/BSCATS-835
			// H:mm, HH:mm, H:mm:ss, HH:mm:ss
			timeSeparated: /^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
			// Hmm, HHmm, Hmmss, HHmmss
			timeNonSeparated: /^([0-9]|[01][0-9]|2[0-3])([0-5][0-9])([0-5][0-9])?$/,

			// Six digits or capital letters (excluding I and O), no check digit
			wkn: /^([A-HJ-NP-Z0-9]){5,6}$/,
			password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z!@#$%^&*()\-_=+|\]}[{"';:/?.>,<])[0-9a-zA-Z!@#$%^&*()-_=+|\]}[{"';:/?.>,<]{8,}$/, // eslint-disable-line max-len
		};
	}

	// add a `helpers` object to the model
	helpers() {
		return {
			isEmpty(value) {
				const empty = !value
					|| typeof value === 'undefined'
					|| `${value} `.trim() === '';

				const emptyType = !(
					_.isBoolean(value)
					|| _.isNumber(value)
					|| _.isArray(value)
					|| _.isObject(value));

				return empty && emptyType;
			},

			// checks if `value` is not empty
			// see `isEmpty()`
			isNotEmpty(value) {
				return !this.isEmpty(value);
			},

			// checks if `value` is higher than zero
			// TODO: this should not return true for the value 0 (zero)
			// 0 neither positive nor negative
			isPositiveNumber(value) {
				return (
					this.isNotEmpty(value)
					&& (this.isNumeric(value) && parseFloat(value) >= 0));
			},

			// checks if `value` is a digit
			// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger polyfill
			isDigit(value) {
				return this.isNumeric(value) && parseFloat(value) === Math.floor(parseFloat(value));
			},

			// checks if `value` is a number
			// see: https://github.com/jquery/jquery/blob/master/src/core.js#L211
			isNumeric(value) {
				return (value - parseFloat(value) + 1) >= 0;
			},
		};
	}

	// validate the complete model
	// returns an array with the names of the invalid attributes
	// an empty array means the model is valid
	validate() {
		return Object
			.keys(this.validation())
			.reduce((memo, attr) => {
				const isValid = this.preValidate(attr, this.get(attr));

				if (!isValid) {
					memo.push(attr);
				}

				return memo;
			}, []);
	}

	// prevalidate a single attribute or a hash of attributes
	// returns boolean for validating a single attribute
	// returns an array with invalid attributes for validating hashes
	preValidate(attribute, value) {
		if (_.isString(attribute)) {
			// validate a single attribute
			return this.validateAttribute(attribute, value);
		} if (_.isObject(attribute)) {
			// validate complete hash
			return this.validateAttributeHash(attribute);
		}

		return true;
	}

	// validates a single attribute in the model (this)
	// returns boolean
	validateAttribute(attribute, value) {
		// no validation object or validation rule for attribute found in the model
		if (!this.validation() || !this.validation().hasOwnProperty(attribute)) {
			this.set(attribute, value);

			return true;
		}

		// covert the rule to an array if it isn't one already
		// store validation rule for the attribute
		const rule = _.isArray(this.validation()[attribute])
			? this.validation()[attribute]
			: [this.validation()[attribute]];

		// loop through array of rules and check if
		// it's a custom function, a helper function, or a pattern
		const results = _.map(rule, (singleRule) => {
			if (_.isFunction(singleRule)) {
				// custom validation function
				return singleRule.call(this, value, this);
			} if (_.isFunction(this.helpers()[singleRule])) {
				// helper function
				return this.helpers()[singleRule](value, this);
			}

			return this.validatePattern(value, singleRule);
		});

		this.set(attribute, value);

		return results.indexOf(false) === -1;
	}

	// validates a hash of attributes by looping through them
	// and executing `validateAttribute`
	// returns an array with all invalid attributes
	// TODO: use Object.keys().reduce()
	validateAttributeHash(hash, updateValue) {
		// validate a hash of attributes/values
		const res = _.reduce(hash, (memo, value, attr) => {
			const isValid = this.validateAttribute(attr, value, updateValue);

			if (isValid === false) {
				memo.push(attr);
			}

			return memo;
		}, []);

		return res;
	}

	// test a string value against a regex from `Model.patterns`
	validatePattern(value, patternName) {
		// pattern not found
		if (!this.patterns || !this.patterns() || !this.patterns().hasOwnProperty(patternName)) {
			return true;
		}

		const pattern = this.patterns()[patternName];

		return this.helpers().isNotEmpty(value) && value.match(pattern) !== null;
	}
}

export default ValidationModel;
