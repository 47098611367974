import Backbone from 'backbone';
import _ from 'underscore';
import * as DOM from '../../utils/dom';
import Eventbus from '../../utils/eventbus';
import Socket from '../../utils/socket/client';

import ClickToTradeFormModel from '../../models/ClickToTradeFormModel';

import AutoCompleteView from '../shared/autocomplete';
import ValidationFormView from '../shared/ValidationFormView';
import ClickToTradeResponseView from './ClickToTradeResponseView';

class ClickToTradeView extends ValidationFormView {
	ui() {
		return {
			toggleMifid2Fields: '.js-toggle-mifid2Fields',
			currencyContainer: '.js-currency-container',
			inputMarketMaker: 'select[name=marketmakerId]',
			tooltipContainer: '.js-tooltip-container',
			mifid2Fields: '#mifid2Fields',
		};
	}

	events() {
		return _.extend({}, super.events(), {
			'change @ui.inputMarketMaker': 'onMarketMakerChange',
			'change @ui.toggleMifid2Fields': 'onToggleMifid2Fields',
		});
	}

	constructor(options) {
		// eslint-disable-next-line no-param-reassign
		options.model = new ClickToTradeFormModel();

		super(options);

		this.listenTo(Eventbus, 'form:submit', this.submitForm);

		// define subviews
		this.views = {
			autoComplete: new AutoCompleteView({ el: this.el, model: this.model }),
		};

		this.setupSocket();
	}

	// do a ajax request with form action, method and data
	handleAction(url, type, data) {
		return Backbone.ajax({
			url,
			type,
			data,
			context: this,
		});
	}

	hideErrorTooltip() {
		this.uiElements.tooltipContainer[0].innerHTML = '';
	}

	onAfterRender() {
		this.toggleCurrencyDropdown();
	}

	// base override
	onFormSubmit(e) {
		e.preventDefault();

		const form = e.target;
		const invalidAttributes = this.validate(true);

		if (invalidAttributes.length === 0) {
			this.submitForm(form);
		}
	}

	// base override
	onFormReset() {
		// give the browser time to reset form before accessing form values again
		setTimeout(() => {
			this.resetForm();
			this.toggleMifid2Fields(false);
		}, 50);
	}

	// base override
	resetForm() {
		const defaults = this.model.defaults();

		super.hideValidationFeedback();

		this.model.set(defaults);

		// (re)set conditional fields
		this.setConditionalFields();

		// empty reference price
		this.uiElements.referencePrice[0].innerHTML = '';
	}

	onMarketMakerChange() {
		this.toggleCurrencyDropdown();
	}

	// handle successful ajax request
	onRequestSuccess(response, status, xhr) {
		// get the custom response header from the request
		const responseAction = xhr.getResponseHeader('Cats-Response-Action');

		this.renderOutputView(response, responseAction);
	}

	onRequestError(error) {
		this.renderOutputView(error.responseText, 'error');
	}

	onToggleMifid2Fields() {
		const [el] = this.uiElements.toggleMifid2Fields;
		const selectedOption = el.options[el.selectedIndex];
		const showPanel = selectedOption.dataset.showMifidFields === 'true';

		this.toggleMifid2Fields(showPanel);
	}

	toggleMifid2Fields(showPanel) {
		const [panel] = this.uiElements.mifid2Fields;

		if (showPanel) {
			panel.classList.remove('is-hidden');
		} else {
			panel.classList.add('is-hidden');
		}
	}

	// render a view depending on the custom response header from the server
	renderOutputView(html, responseAction = 'error') {
		// no need for response / modal view
		// render it in the DOM
		if (responseAction === 'cancelled' || responseAction === 'error') {
			Eventbus.trigger('modal:hide');

			this.showErrorTooltip(html);

			return;
		}

		const modalHasEventListeners = true;

		Eventbus.trigger(
			'modal:show:view',
			new ClickToTradeResponseView().render(html),
			modalHasEventListeners,
		);
	}

	setupSocket() {
		// create socket
		if (window.config && window.config.signalR) {
			this.socket = new Socket(window.config.signalR);
		}
	}

	// submit form through ajax
	submitForm(form) {
		const { action, method } = form;
		const data = DOM.serializeForm(form);

		this.handleAction(action, method, data).then(
			this.onRequestSuccess.bind(this),
			this.onRequestError.bind(this),
		);

		this.hideErrorTooltip();
	}

	// called from Ajax Error call
	showErrorTooltip(html) {
		Eventbus.trigger('modal:hide');
		this.uiElements.tooltipContainer[0].innerHTML = html;
	}

	// toggle the currency dropdown based on the selected market maker
	toggleCurrencyDropdown() {
		const [marketMakerDropdown] = this.uiElements.inputMarketMaker;
		const [currencyContainer] = this.uiElements.currencyContainer;

		if (!marketMakerDropdown || !currencyContainer) {
			return;
		}

		const selectedOption = marketMakerDropdown.options[marketMakerDropdown.selectedIndex];
		const hasCurrency = selectedOption.getAttribute('data-currency-selector') !== null;

		if (hasCurrency) {
			currencyContainer.classList.remove('is-hidden');
		} else {
			currencyContainer.classList.add('is-hidden');
		}
	}
}

export default ClickToTradeView;
