import _ from 'underscore';
import ValidationModel from './ValidationModel';

function getDate() {
	const date = new Date();
	const year = date.getFullYear();
	let day = date.getDate();
	let month = parseInt(date.getMonth() + 1, 10);

	if (day < 10) {
		day = `0${day}`;
	}

	if (month < 10) {
		month = `0${month}`;
	}

	return `${day}-${month}-${year}`;
}

const date = getDate();

class LimitTradingFormModel extends ValidationModel {
	initialize(attributes, options) {
		super.initialize(attributes, options);

		// valid values for `orderType`
		this.orderTypes = ['0', '1', '2', '3', '4', '5', '6', '7'];

		// valid values for `orderValidity`
		this.orderValidities = ['0', '1', '2'];
	}

	defaults() {
		return {
			allowPartial: true,
			brokerId: null,
			customerReference: null,
			buy: 'true',
			currency: 'EUR',
			deltaValue: null,
			expireDate: date,
			instrumentIdentifier: null,
			marketmakerId: null,
			orderType: '2',
			orderValidity: null,
			price: null,
			stopPrice: 0,
			volume: null,
		};
	}

	validation() {
		return _.extend({}, super.validation(), {
			brokerId: 'isNotEmpty',

			customerReference: ['isNotEmpty', 'alphanumericSpace'],

			buy(value) {
				return value === 'true' || value === 'false';
			},

			// can't be empty, must be a currency code
			currency: ['isNotEmpty', 'currency'],

			// can't be empty, but only required
			// with order type '7'
			deltaValue(value) {
				const orderType = this.get('orderType');

				if (orderType !== '7') {
					return true;
				}

				return this.helpers().isPositiveNumber(value)
					&& parseFloat(value) <= 9999.9999
					&& parseFloat(value) >= 0.0001;
			},

			// expireDate can't be empty when `orderValidity` is `0` (0 = Date)
			// has to be a valid date (dd-mm-yyyy)
			expireDate(value) {
				const orderValidity = this.get('orderValidity');

				if (orderValidity !== '0') {
					// no need to validate
					return true;
				}

				return this.patterns().date.test(value);
			},

			// can't be empty
			marketmakerId: 'isNotEmpty',

			// can't be empty, must be an ISIN or WKN
			instrumentIdentifier(value) {
				const isIsin = this.patterns().isin.test(value);
				const isWkn = this.patterns().wkn.test(value);

				return this.helpers().isNotEmpty(value) && (isIsin || isWkn);
			},

			orderType(value) {
				return this.orderTypes.indexOf(value) !== -1;
			},

			// only required with certain order types
			// can be empty, but if not, it must be positive digit
			price(value) {
				const mandatoryForOrderTypes = ['0', '2', '4', '5'];
				const orderType = this.get('orderType');

				// no validation needed
				if (mandatoryForOrderTypes.indexOf(orderType) === -1) {
					return true;
				}

				// check if value is number and positive
				return this.helpers().isPositiveNumber(value)
					&& parseFloat(value) <= 9999999999.99
					&& parseFloat(value) >= 0.0001;
			},

			stopPrice(value) {
				const mandatoryForOrderTypes = ['0', '1', '4', '5', '6', '7'];
				const orderType = this.get('orderType');

				if (mandatoryForOrderTypes.indexOf(orderType) === -1) {
					return true;
				}

				return this.helpers().isPositiveNumber(value)
					&& parseFloat(value) <= 9999999999.99
					&& parseFloat(value) >= 0.0001;
			},

			// can't be empty, must be positive digit with a minimum value of 1, maximum value of 99999999
			volume(value) {
				return this.helpers().isNotEmpty(value)
					&& this.helpers().isDigit(value)
					&& this.helpers().isPositiveNumber(value)
					&& parseInt(value, 10) >= 1
					&& parseInt(value, 10) <= 99999999;
			},
		});
	}
}

export default LimitTradingFormModel;
