import BaseView from '../../BaseView';
import SearchFormView from './SearchFormView';
import SearchResultsView from './SearchResultsView';

// Search View
// Used as base class for Executions, Orders, Quotes
// --------------------------------------------------
class SearchIndexView extends BaseView {
	ui() {
		return {};
	}

	constructor(options = {}) {
		super(options);

		this.resultType = 'result';

		// override (sub)views in extending views
		// before `render` is called
		if (options.views) {
			this.views = options.views;
		} else {
			this.views = {
				form: new SearchFormView({ el: '.js-filters' }),
			};

			if (document.querySelector('.js-table')) {
				this.views.result = new SearchResultsView({ el: '.js-table' });
			}
		}
	}

	getCancelLabel(numSelected) {
		const labelCount = numSelected > 0 ? numSelected : '';
		const labelType = numSelected === 0 || numSelected > 1
			? `${this.resultType}s`
			: this.resultType;

		const label = `Cancel ${labelCount} ${labelType}`;

		return label;
	}
}

export default SearchIndexView;
