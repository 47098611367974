import _ from 'underscore';

import SearchView from '../shared/search/SearchView';
import ExecutionsFormView from './ExecutionsFormView';
import ExecutionsResultView from './ExecutionsResultView';

// Search Executions View, extends `search/index`
// --------------------------------------------
class ExecutionsView extends SearchView {
	ui() {
		return _.extend({}, super.ui(), {
			cancelButton: '.js-cancel',
			// downloadButton: '.js-download',
		});
	}

	// override initialize
	constructor(options = {}) {
		// define subviews to be rendered by BaseView
		// in the `onRender` method
		// eslint-disable-next-line no-param-reassign
		options.views = {
			form: new ExecutionsFormView({ el: '.js-filters' }),
		};

		// without results, there is no table
		if (document.querySelector('.js-table')) {
			// eslint-disable-next-line no-param-reassign
			options.views.result = new ExecutionsResultView({ el: '.js-table' });
		}

		super(options);

		this.resultType = 'execution';

		if (options.views.result) {
			this.listenTo(this.views.result.model, 'change:numSelected', this.onNumSelectedChange);
		}
	}

	onNumSelectedChange(model, numSelected) {
		// base call
		const buttonLabel = this.getCancelLabel(numSelected);

		this.toggleCancelButton(numSelected === 0);
		this.uiElements.cancelButton[0].textContent = buttonLabel;
	}

	toggleCancelButton(disabled) {
		this.uiElements.cancelButton[0].disabled = disabled;
	}
}

export default ExecutionsView;
