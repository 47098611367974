import { Model } from 'backbone';

class OrderResultModel extends Model {
	defaults() {
		return {
			numSelected: 0,
			totalInputs: 0,
			allSelected: false,
		};
	}

	initialize() {
		this.listenTo(this, 'change:numSelected', this.onNumSelectedChange);
	}

	onNumSelectedChange(model, numSelected) {
		const totalInputs = this.get('totalInputs');

		this.set('allSelected', numSelected === totalInputs);
	}
}

export default OrderResultModel;
