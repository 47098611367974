const events = {
	CONNECTED: 'socket.connection.connected',
	DISCONNECTED: 'socket.connection.disconnected',
	RECONNECTING: 'socket.connection.reconnecting',
	ERROR: 'socket.connection.error',

	SUBSCRIBE: 'socket.subscribe',
	UNSUBSCRIBE: 'socket.unsubscribe',

	QUOTE: 'socket.hub.quote',
	TRADE: 'socket.hub.trade',
	TICK: 'socket.hub.tick',
	LOG: 'socket.hub.log',
};

export default events;
