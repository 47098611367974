import SearchResultsView from '../shared/search/SearchResultsView';
import ExecutionResultModel from '../../models/ExecutionResultModel';

class ExecutionsResultView extends SearchResultsView {
	initialize() {
		this.model = new ExecutionResultModel();

		super.initialize();
	}
}

export default ExecutionsResultView;
