import Visualization from './visualization';
import ChartVisualization from './chart-visualization';

export default class VisualizationListModel {
	constructor(visualizationList = []) {
		visualizationList.forEach((visualization) => {
			if (
				!(visualization instanceof Visualization)
				&& !(visualization instanceof ChartVisualization)
			) {
				throw new Error(`Visualization "${visualization}" is not a DashboardStatisticVisualization or DashboardStatisticChartVisualization`);
			}
		});

		this._visualizationList = visualizationList;
	}

	getCharts() {
		return this._visualizationList
			.filter((v) => v instanceof ChartVisualization)
			.map((v) => v.chart);
	}

	getElements() {
		return this._visualizationList.map((v) => v.element);
	}
}
