import autoComplete from 'autocomplete-js';

import ValidationFormView from './ValidationFormView';

class AutoCompleteView extends ValidationFormView {
	ui() {
		return {
			autocomplete: '.js-autocomplete',
			autocompleteDescription: '.js-autocomplete-description',
			inputBrokerId: 'select[name=brokerId]',
			inputMarketmakerId: 'select[name=marketmakerId]',
		};
	}

	events() {
		return {
			'input @ui.autocomplete': 'clearAutocompleteDescription',
		};
	}

	get autocompletDescription() {
		const { autocompleteDescription: [el] } = this.uiElements;

		return el;
	}

	onAfterRender() {
		const self = this;
		const { autocompletDescription } = this;

		autocompletDescription.dataset.labelEmpty = autocompletDescription.textContent;

		autoComplete({
			EmptyMessage: 'No item found',
			MinChars: 3,
			QueryArg: 'query',

			// Required to fix a bug in Chrome, IE and Safari
			// https://github.com/autocompletejs/autocomplete.js/issues/29#issuecomment-426992150
			_Blur(event, now = false) {
				if (now) {
					this._Close();

					return;
				}

				setTimeout(() => this._Blur(event, true), 250);
			},

			// Override because inline CSS is blocked by CSP
			_Position() {
				this.DOMResults.setAttribute('class', 'autocomplete');
			},

			_Post(response) {
				return JSON.parse(response);
			},

			_RenderResponseItems(responseItems) {
				const data = responseItems.map((item) => ({
					Value: item.Value.substring(0, 12),
					Label: item.Label.trim(),
				}));

				// eslint-disable-next-line no-underscore-dangle
				return autoComplete.defaults._RenderResponseItems.call(this, data);
			},

			_Url() {
				const brokerId = (
					self.uiElements.inputBrokerId.length
					&& self.uiElements.inputBrokerId[0].value
				);
				const marketmakerId = self.uiElements.inputMarketmakerId[0].value;

				return `/search/instruments?brokerId=${brokerId}&marketmakerId=${marketmakerId}`;
			},

			_Select(item) {
				// eslint-disable-next-line no-underscore-dangle
				const value = autoComplete.defaults._Select.call(this, item);
				const description = item.textContent.trim().substring(14);

				self.validateField(this.Input);

				if (description) {
					autocompletDescription.textContent = description;
				} else {
					self.clearAutocompleteDescription();
				}

				return value;
			},
		}, self.uiElements.autocomplete[0]);
	}

	clearAutocompleteDescription() {
		const { autocompletDescription } = this;

		autocompletDescription.textContent = autocompletDescription.dataset.labelEmpty;
	}
}

export default AutoCompleteView;
