// Util for working with DOM elements
// ----------------------------------

// loop over NodeList
function forEach(array, callback, scope) {
	let i;

	for (i = 0; i < array.length; i += 1) {
		callback.call(scope, array[i], i, array);
	}
}

// convert a form to object with key-value pairs
function formToObject(form) {
	const fields = form.querySelectorAll('input, textarea, select');
	const obj = {};

	forEach(fields, (field) => {
		const { name } = field;
		const { value } = field;
		const isDisabled = field.disabled;
		const isRadio = field.type === 'radio';
		const isCheckbox = field.type === 'checkbox';
		const isChecked = field.checked === true;

		if (isCheckbox) {
			if (!obj[name]) {
				// default value: empty
				obj[name] = '';
			}

			// store checked values in an array
			if (isChecked) {
				if (obj[name] === '') {
					obj[name] = [];
				}
				obj[name].push(value);
			}
		} else if (isRadio) {
			if (isChecked) {
				// store the check value, only one possible with radio's
				obj[name] = value;
			} else if (!obj[name]) {
				// default value: empty
				obj[name] = '';
			}
		} else {
			// store value or empty
			obj[name] = value || '';
		}

		// remove disabled fields
		if (isDisabled && obj[name] && !(isCheckbox || isRadio)) {
			delete obj[name];
		}
	});

	return obj;
}

// serialize a form to a string
function serializeForm(form) {
	const obj = formToObject(form);
	const objKeys = Object.keys(obj);

	return objKeys.reduce((arr, key) => {
		arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);

		return arr;
	}, []).join('&');
}

// http://exploringjs.com/es6/ch_modules.html
// same as:
// `export function serializeFrom[..]`
// `export function formToObject[..]`
export {
	forEach,
	formToObject,
	serializeForm,
};
