import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const dateFormatted = moment().format('DD-MM-YYYY');

class ExecutionFormModel extends SearchFormModel {
	defaults() {
		return {
			catsEndUserId: '',
			customerReference: '',
			brokerId: null,
			catsExecutionId: null,
			dateFrom: dateFormatted,
			dateTo: dateFormatted,
			equalTo: '',
			executionPrice: null,
			greaterThan: '',
			instrumentIdentifier: null,
			lessThan: '',
			marketmakerId: null,
			quantity: null,
			resultsPerPage: 25,
			systemName: null,
			timeFrom: '00:00:00',
			timeTo: '00:00:00',
			page: 1,
		};
	}
}

export default ExecutionFormModel;
