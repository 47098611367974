import svg4everybody from 'svg4everybody';
import Backbone from 'backbone';

import ModuleLoader from './utils/moduleLoader';
import AppRouter from './app/router';
import modules from './modules';

svg4everybody();

Backbone.$ = window.$;

class App {
	constructor() {
		this.loader = new ModuleLoader(modules);
		this.router = new AppRouter();

		Backbone.history.start({
			pushState: true,
			hashChange: false,
			root: '/',
		});

		this.loader.load();
	}
}

window.app = new App();
