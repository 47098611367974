import _ from 'underscore';
import Backbone from 'backbone';
import Eventbus from '../../utils/eventbus';
import * as DOM from '../../utils/dom';

import OrdersFormView from './OrdersFormView';
import ResultView from './OrdersResultView';
import SearchView from '../shared/search/SearchView';
import LimitTradingResponseView from '../limitTrading/LimitTradingResponseView';

// Search Orders View, extends `search/index`
// ------------------------------------------
class OrdersView extends SearchView {
	ui() {
		return _.extend({}, super.ui(), {
			buttonBulkAction: '.js-bulk-action',
			buttonBulkActionCancel: '.js-bulk-action-cancel',
			tooltipContainer: '.js-tooltip-container',
			body: '.js-site-wrapper',
		});
	}

	events() {
		return {
			'change @ui.statusSelect': 'onStatusSelectChange',
			'click @ui.buttonBulkActionCancel': 'onButtonCancelClick',
			'click @ui.orderDetail': 'onDetailClick',
		};
	}

	constructor(options = {}) {
		// define subviews to be rendered by BaseView
		// in the `onRender` method
		// eslint-disable-next-line no-param-reassign
		options.views = {
			form: new OrdersFormView({ el: '.js-filters' }),
		};

		// without results, there is no table
		if (document.querySelector('.js-table')) {
			// eslint-disable-next-line no-param-reassign
			options.views.result = new ResultView({ el: '.js-table' });
		}

		super(options);

		this.onRequestSuccess = this.onRequestSuccess.bind(this);
		this.onRequestError = this.onRequestError.bind(this);

		this.listenTo(Eventbus, 'form:submit', this.onFormSubmit);

		if (this.views.result) {
			this.listenTo(this.views.result.model, 'change:numSelected', this.onRowsSelectedChange);
		}
	}

	handleAction(url, type = 'GET', data = {}) {
		Backbone
			.ajax({ url, type, data })
			.then(this.onRequestSuccess, this.onRequestError);
	}

	handleForm(form, formAction = null, formMethod = null) {
		const action = formAction || form.action;
		const method = formMethod || form.method;
		const data = DOM.serializeForm(form);

		this.handleAction(action, method, data);
	}

	handleResponseCancelError(response) {
		this.showTooltip(response);
	}

	handleResponseCancelPending(response) {
		this.showTooltip(response);
	}

	handleResponseCancelMifidModal(response) {
		const modalHasEventListeners = true;
		Eventbus.trigger('modal:show:view', new LimitTradingResponseView().render(response), modalHasEventListeners);
	}

	handleResponseCancelSuccess(response) {
		this.showTooltip(response);
		this.preventUserInteraction();
		setTimeout(() => { window.location.reload(); }, 2000);
	}

	handleResponseOrderDetailsModal(response) {
		Eventbus.trigger('modal:show:view', new LimitTradingResponseView().render(response));
	}

	onButtonCancelClick(e) {
		e.preventDefault();

		this.handleForm(
			e.target.closest('form'),
			e.target.getAttribute('formaction'),
			e.target.getAttribute('formmethod'),
		);
	}

	onFormSubmit(form) {
		this.handleForm(form);
	}

	onRequestError(error) {
		// show modal with error message
		Eventbus.trigger('modal:show:html', error.responseText);
	}

	// handle successful ajax request
	onRequestSuccess(response, status, xhr) {
		// get the custom response header from the request
		const responseAction = xhr.getResponseHeader('Cats-Response-Action');

		switch (responseAction) {
			case 'cancel-error':
				this.handleResponseCancelError(response);
				return;
			case 'cancel-pending':
				this.handleResponseCancelPending(response);
				return;
			case 'cancel-success':
				this.handleResponseCancelSuccess(response);
				return;
			case 'cancel-mifid-order':
				this.handleResponseCancelMifidModal(response);
				return;
			case 'order-details':
			default:
				this.handleResponseOrderDetailsModal(response);
		}
	}

	onRowsSelectedChange(model, numSelected) {
		this.toggleButtonLabels(numSelected);
	}

	preventUserInteraction() {
		document.querySelector('.js-site-wrapper').classList.add('no-click');
	}

	showTooltip(html) {
		Eventbus.trigger('modal:hide');
		this.uiElements.tooltipContainer[0].innerHTML = html;
	}

	toggleButtonLabels(numSelected) {
		const buttons = this.uiElements.buttonBulkAction;
		const disabled = numSelected === 0;

		buttons.forEach((button) => {
			const buttonLabel = numSelected > 1
				? button.getAttribute('data-label-multiple')
				: button.getAttribute('data-label-single');

			const labelSuffix = numSelected > 0
				? ` (${numSelected})`
				: '';

			// eslint-disable-next-line no-param-reassign
			button.value = `${buttonLabel}${labelSuffix}`;
			// eslint-disable-next-line no-param-reassign
			button.disabled = disabled;
		});
	}
}

export default OrdersView;
