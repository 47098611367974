import BaseView from '../BaseView';

class LoaderView extends BaseView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.className = 'loader is-hidden';

		super(options);
	}

	onAfterRender() {
		this.el.innerHTML = `
			<div class="loader__item-container">
				<div class="loader__item"></div>
				<div class="loader__item"></div>
				<div class="loader__item"></div>
				<div class="loader__item"></div>
			</div>
		`;
	}

	toggle(show) {
		// https://connect.microsoft.com/IE/feedback/details/878564/element-classlist-toggle-does-not-support-second-parameter
		if (show) {
			this.el.classList.remove('is-hidden');
		} else {
			this.el.classList.add('is-hidden');
		}
	}
}

export default LoaderView;
