import _ from 'underscore';
import $ from './jquery.signalR-custom';

import SocketEvents from '../../events/socket';
import Eventbus from '../eventbus';

const defaults = {
	data: null,
	debug: false,
	logging: false,
	hubName: 'notificationHub',
	signalRUrl: '',
	token: null,
	useDefaultPath: false,
};

class Client {
	constructor(options) {
		this.options = _.extend({}, defaults, options || {});

		// setup connection and hub
		this.connection = $.hubConnection(this.options.signalRUrl, this.options);

		this.connection.logging = true;
		if (this.options.token !== null) {
			this.connection.qs = { token: this.options.token };
		}

		this.hub = this.connection.createHubProxy(this.options.hubName);

		// Enable cross-domain requests (CORS)
		this.hub.url = this.options.signalRUrl;

		// initialize connection and hub events
		this.initEvents();

		// connect
		this.connect();
	}

	initEvents() {
		// connection events
		this.connection.reconnecting(this.onReconnecting);
		this.connection.disconnected(this.onDisconnected);

		// eventbus events
		Eventbus.on(SocketEvents.SUBSCRIBE, this.subscribe.bind(this));
		Eventbus.on(SocketEvents.UNSUBSCRIBE, this.unsubscribe.bind(this));

		// hub events
		this.hub.on('quote', (res) => this.onQuote(res));
		this.hub.on('trade', (res) => this.onTrade(res));
		this.hub.on('publish', (res) => this.onTick(res));
	}

	// connect with the server
	connect() {
		this.connection.start({ transport: ['webSockets', 'longPolling', 'serverSentEvents'] })
			.done(this.connectionEstablished.bind(this))
			.fail(this.connectionError.bind(this));
	}

	// disconnect from server
	disconnect() {
		this.connection.stop();
	}

	// event handlers
	// socket reconnecting
	onReconnecting() {
		Eventbus.trigger(SocketEvents.RECONNECTING);
	}

	// socket disconnected
	onDisconnected() {
		Eventbus.trigger(SocketEvents.DISCONNECTED);
	}

	// socket connected
	// fires event with connection ID
	connectionEstablished() {
		Eventbus.trigger(SocketEvents.CONNECTED, this.connection.id);

		const { data } = this.options;

		if (data) {
			this.subscribe(data);
		}
	}

	subscribe(data) {
		this.hub.invoke('Subscribe', data);
	}

	unsubscribe(data) {
		this.hub.invoke('Unsubscribe', data);
	}

	// socket connection error
	// fires event with message and error event
	connectionError(e) {
		Eventbus.trigger(SocketEvents.ERROR, e.message, e);
	}

	onTick(response) {
		Eventbus.trigger(SocketEvents.TICK, response);
	}

	// hub notification received
	onQuote(response) {
		const data = JSON.parse(response);

		Eventbus.trigger(SocketEvents.QUOTE, data);
	}

	// hub notification received
	onTrade(response) {
		const data = JSON.parse(response);

		Eventbus.trigger(SocketEvents.TRADE, data);
	}
}

export default Client;
