export default class VisualizationModel {
	constructor(element) {
		this.element = element;
	}

	static fromString(string) {
		const range = document.createRange();

		range.setStart(document.body, 0);

		return new VisualizationModel(range
			.createContextualFragment(string.trim())
			.firstChild);
	}
}
