import SearchFormView from '../shared/search/SearchFormView';
import OrderFormModel from '../../models/OrderFormModel';

class OrderFormView extends SearchFormView {
	constructor(options) {
		// eslint-disable-next-line no-param-reassign
		options.model = new OrderFormModel();

		super(options);
	}
}

export default OrderFormView;
