import _ from 'underscore';
import moment from 'moment';
import autoComplete from 'autocomplete-js';

import Eventbus from '../../utils/eventbus';

import ValidationFormView from '../shared/ValidationFormView';
import RfqTradingFormModel from '../../models/RfqTradingFormModel';
import tooltipTemplate from '../../../../../Views/Shared/Partials/_Tooltip.mustache';

class RfqTradingFormView extends ValidationFormView {
	ui() {
		return _.extend({}, super.ui(), {
			toggleExecution: '.js-toggle-execution',
			toggleMifid2Fields: '.js-toggle-mifid2Fields',
			executionForm: '.js-execution-form',
			btnQuote: '.js-button-quote',
			btnExecution: '.js-button-execution',
			tooltipContainer: '.js-tooltip-container',
			inputPrice: '.js-target-price',
			inputReference: '.js-reference',
			inputBrokerId: 'select[name=brokerId]',
			inputMarketmakerId: 'select[name=marketmakerId]',
			inputVolume: '.js-volume',
			volumeConfirmAmount: '.js-volume-confirm-amount',
			autocomplete: '.js-autocomplete',
			autocompleteDescription: '.js-autocomplete-description',

			inputMarketMaker: 'select[name=marketmakerId]',
			currencyContainer: '.js-currency-container',
			mifid2Fields: '#mifid2Fields',
		});
	}

	events() {
		return _.extend({}, super.events(), {
			reset: 'onFormReset',
			'change @ui.inputMarketMaker': 'onMarketMakerChange',
			'change @ui.toggleExecution': 'onToggleExecution',
			'change @ui.toggleMifid2Fields': 'onToggleMifid2Fields',
			'input @ui.inputReference': 'onReferenceChange',
			'input @ui.autocomplete': 'clearAutocompleteDescription',
		});
	}

	constructor(options = {}) {
		const config = window.config || {};
		const volumeConfirmAmount = config.volumeConfirmAmount || 0;

		// eslint-disable-next-line no-param-reassign
		options.model = new RfqTradingFormModel({
			volumeConfirmAmount,
		});

		super(options);
	}

	clearAutocompleteDescription() {
		const [description] = this.uiElements.autocompleteDescription;

		description.textContent = description.dataset.labelEmpty;
	}

	// handler for 'Execution at target price' checkbox
	onToggleExecution() {
		const [{ checked }] = this.uiElements.toggleExecution;

		this.toggleExecutionForm(checked);
	}

	onToggleMifid2Fields() {
		const [{ checked }] = this.uiElements.toggleMifid2Fields;

		this.toggleMifid2Fields(checked);
	}

	onReferenceChange(e) {
		const field = e.target;

		// field.value = field.value.toUpperCase();

		RfqTradingFormView.toUpper(field.value);
	}

	static toUpper(value) {
		value.toUpperCase();
	}

	onMarketMakerChange() {
		this.toggleCurrencyDropdown();
	}

	// toggle the currency dropdown based on the selected market maker
	toggleCurrencyDropdown() {
		const [marketMakerDropdown] = this.uiElements.inputMarketMaker;
		const [currencyContainer] = this.uiElements.currencyContainer;

		if (!marketMakerDropdown || !currencyContainer) {
			return;
		}

		const selectedOption = marketMakerDropdown.options[marketMakerDropdown.selectedIndex];
		const hasCurrency = selectedOption.getAttribute('data-currency-selector') !== null;

		if (hasCurrency) {
			currencyContainer.classList.remove('is-hidden');
		} else {
			currencyContainer.classList.add('is-hidden');
		}
	}

	// show the 'Execution at target price' form
	toggleExecutionForm(show) {
		this.hideErrorTooltip();

		// https://connect.microsoft.com/IE/feedback/details/878564/element-classlist-toggle-does-not-support-second-parameter
		if (show) {
			this.uiElements.executionForm[0].classList.remove('is-hidden');
			this.uiElements.btnExecution[0].classList.remove('is-hidden');
			this.uiElements.btnQuote[0].classList.add('is-hidden');
			this.uiElements.inputPrice[0].focus();
		} else {
			this.uiElements.executionForm[0].classList.add('is-hidden');
			this.uiElements.btnExecution[0].classList.add('is-hidden');
			this.uiElements.btnQuote[0].classList.remove('is-hidden');
			this.uiElements.inputPrice[0].value = '';
		}

		this.uiElements.toggleExecution[0].checked = show;
	}

	toggleMifid2Fields(show) {
		if (show) {
			this.uiElements.mifid2Fields[0].classList.remove('is-hidden');
		} else {
			this.uiElements.mifid2Fields[0].classList.add('is-hidden');
		}

		this.uiElements.toggleMifid2Fields[0].checked = show;
	}

	onFormReset() {
		this.toggleExecutionForm(false);
		this.toggleMifid2Fields(false);
	}

	// base override
	onFormSubmit(e) {
		const form = e.target;
		const invalidAttributes = this.validate(true);
		const [inputReference] = this.uiElements.inputReference;

		e.preventDefault();

		if (invalidAttributes.length === 0) {
			// hide tooltip
			this.hideErrorTooltip();

			// trigger event catched by index
			Eventbus.trigger('form:submit', form);
		}

		// Input reference must be set after form submit, to prevent user input from getting lost
		inputReference.value = inputReference.dataset.accountid + moment().format('DDHHmmss');
	}

	renderErrorTooltip(data) {
		return tooltipTemplate.render(data);
	}

	// called from index.js on Ajax Error call
	showErrorTooltip(errorHTML) {
		this.uiElements.tooltipContainer[0].innerHTML = errorHTML;
	}

	hideErrorTooltip() {
		this.uiElements.tooltipContainer[0].innerHTML = '';
	}

	onAfterRender() {
		const self = this;
		const [autocompleteDescription] = this.uiElements.autocompleteDescription;

		this.toggleCurrencyDropdown();

		autocompleteDescription.dataset.labelEmpty = autocompleteDescription.textContent;

		autoComplete({
			EmptyMessage: 'No item found',
			MinChars: 3,
			QueryArg: 'query',

			// Required to fix a bug in Chrome, IE and Safari
			// https://github.com/autocompletejs/autocomplete.js/issues/29#issuecomment-426992150
			_Blur(event, now = false) {
				if (now) {
					this._Close();

					return;
				}

				setTimeout(() => this._Blur(event, true), 250);
			},

			// Override because inline CSS is blocked by CSP
			_Position() {
				this.DOMResults.setAttribute('class', 'autocomplete');
			},

			_Post(response) {
				return JSON.parse(response);
			},

			_RenderResponseItems(responseItems) {
				const data = responseItems.map((item) => ({
					Value: item.Value.substring(0, 12),
					Label: item.Label,
				}));

				// eslint-disable-next-line no-underscore-dangle
				return autoComplete.defaults._RenderResponseItems.call(this, data);
			},

			_Url() {
				const brokerId = self.ui().inputBrokerId[0].value;
				const marketmakerId = self.ui().inputMarketmakerId[0].value;

				return `/search/instruments?brokerId=${brokerId}&marketmakerId=${marketmakerId}`;
			},

			_Select(item) {
				// eslint-disable-next-line no-underscore-dangle
				const value = autoComplete.defaults._Select.call(this, item);
				const description = item.textContent.trim().substring(14);

				self.validateField(this.Input);

				if (description) {
					autocompleteDescription.textContent = description;
				} else {
					this.clearAutocompleteDescription();
				}

				return value;
			},
		}, '.js-autocomplete');
	}
}

export default RfqTradingFormView;
