import eventbus from '../app/utils/eventbus';
import VisualizationListFactory from '../factories/visualization-list';
import GroupedVisualizationListModel from '../models/grouped-visualization-list';

export default class DashboardVisualization {
	constructor(el) {
		this.el = el;

		this.charts = [];
		this.container = this.el.querySelector('.js-container');
	}

	start() {
		const { id } = this.el.dataset;
		const name = id.substring(0, 1).toUpperCase() + id.substring(1);
		const createVisualization = VisualizationListFactory[`create${name}Visualization`];

		if (typeof createVisualization !== 'function') {
			return;
		}

		this.container.innerHTML = DashboardVisualization.createLoader();
		this.el.hidden = false;

		DashboardVisualization.getData(this.el.dataset.url, (data) => {
			try {
				const visualizationList = createVisualization.call(VisualizationListFactory, data);
				const isGrouped = visualizationList instanceof GroupedVisualizationListModel;

				if (isGrouped) {
					this.container.classList.add('tab-list');
				}

				this.container.innerHTML = isGrouped
					? DashboardVisualization.createTabHeader(visualizationList.getGroups())
					: '';

				if (isGrouped) {
					Object.values(visualizationList.getElementsPerGroup())
						.forEach((elementList) => {
							const tabItem = DashboardVisualization.createTabItem();

							tabItem.hidden = true;

							elementList.forEach((element) => tabItem.appendChild(element));

							this.container.appendChild(tabItem);
						});
				} else {
					visualizationList.getElements().forEach((element) => {
						this.container.appendChild(element);
					});
				}

				this.charts = this.charts.concat(visualizationList.getCharts());

				this.update(0);
			} catch (error) {
				console.error(`Failed to visualize "${this.el.dataset.url}"`);
				console.error(error);

				this.el.hidden = true;
			}
		});

		this.el.addEventListener('click', (event) => this.onClickHandler(event));

		eventbus.on('menu-collapse-toggle', () => this.resizeCharts());
	}

	onClickHandler(event) {
		if (!event.target.dataset.hasOwnProperty('tabTrigger')) {
			return;
		}

		const triggers = Array.from(this.container.querySelectorAll('[data-tab-trigger]'));
		const triggerIndex = triggers.indexOf(event.target);

		this.update(triggerIndex);
	}

	resizeCharts() {
		this.charts.forEach((chart) => chart.resize());
	}

	update(tabIndex) {
		const triggers = Array.from(this.container.querySelectorAll('[data-tab-trigger]'));
		const tabs = Array.from(this.container.querySelectorAll('[data-tab-item]'));

		triggers.forEach((trigger, index) => {
			if (index === tabIndex) {
				trigger.classList.add('is-active');
			} else {
				trigger.classList.remove('is-active');
			}
		});

		tabs.forEach((tab, index) => {
			// eslint-disable-next-line no-param-reassign
			tab.hidden = index !== tabIndex;
		});

		this.resizeCharts();
	}

	static getData(url, callback) {
		const request = new XMLHttpRequest();

		request.addEventListener('load', () => {
			let data = null;

			try {
				data = JSON.parse(request.responseText);
			} catch (error) {
				console.error(`Unable to parse "${url}"`);
				console.error(error);
			}

			callback(data);
		});

		request.open('GET', url);
		request.send();
	}

	static createLoader() {
		return `<div class="loader-container-static">
			<div class="loader__item-container">
				<div class="loader__item"></div>
				<div class="loader__item"></div>
				<div class="loader__item"></div>
				<div class="loader__item"></div>
			</div>
		</div>`;
	}

	static createTabHeader(labels) {
		return `<div class="tab-list__header">${labels
			.map((label) => `<button type="button" class="tab-list__button" data-tab-trigger>${label}</button>`)
			.join('')}
		</div>`;
	}

	static createTabItem() {
		const tabItem = document.createElement('div');

		tabItem.classList.add('tab-list__item');
		tabItem.dataset.tabItem = true;

		return tabItem;
	}
}
