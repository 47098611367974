import _ from 'underscore';
import ValidationModel from './ValidationModel';

class AccountPasswordModel extends ValidationModel {
	defaults() {
		return {
			userToken: '',
			'Detail.OldPassword': '',
			Password: '',
			PasswordConfirmation: '',
		};
	}

	validation() {
		return _.extend({}, super.validation(), {

			// Can be empty, but only when
			// a userToken (set-password) has been set.
			passwordOld(value, model) {
				const token = model.get('userToken');

				if (token && !value) {
					return true;
				}

				return this.helpers().isNotEmpty(value);
			},

			passwordNew(value) {
				return this.helpers().isNotEmpty(value);
			},

			passwordConfirm(value, model) {
				const passwordNew = model.get('passwordNew');
				const isSame = value === passwordNew;
				const notEmpty = this.helpers().isNotEmpty(value);

				return notEmpty && isSame;
			},
		});
	}
}

export default AccountPasswordModel;
