import Chart from 'chart.js';

const chartSettings = {
	colors: [
		// data-green
		'#009696',
		// data-blue
		'#2846be',
		// data-yellow
		'#ffc800',
		// data-light-blue
		'#239bd7',
		// data-pink
		'#f06450',
		// data-purple
		'#643278',
		// data-dark-blue
		'#193278',
		// data-magenta
		'#c83cff',
	],
	fontColor: '#3c3c3c',
	fontFamily: '"EuclidCircularB", sans-serif',
	fontSize: 14,
};

Chart.defaults.global.animation.duration = 125;
Chart.defaults.global.defaultFontColor = chartSettings.fontColor;
Chart.defaults.global.defaultFontFamily = chartSettings.fontFamily;
Chart.defaults.global.defaultFontSize = chartSettings.fontSize;
Chart.platform.disableCSSInjection = true;

export default chartSettings;
