import Backbone from 'backbone';
import Eventbus from '../../utils/eventbus';
import * as DOM from '../../utils/dom';
import SocketClient from '../../utils/socket/client';
import SocketEvents from '../../events/socket';

import BaseView from '../BaseView';
import RfqTradingFormView from './RfqTradingFormView';
import RfqTradingResponseView from './RfqTradingResponseView';
import RfqTradingResponseDetailView from './RfqTradingResponseDetailView';
import RfqTradingResponseTradeView from './RfqTradingResponseTradeView';
import RfqTradingWaitView from './RfqTradingWaitView';

import RfqTradingQuoteModel from '../../models/RfqTradingQuoteModel';
import QuoteTradeModel from '../../models/QuoteTradeModel';

const RESPONSE_VIEWS = {
	default: RfqTradingResponseView,
	trade: RfqTradingResponseDetailView,
	side: RfqTradingResponseView,
	confirm: RfqTradingResponseView,
	wait: RfqTradingWaitView,
	traded: RfqTradingResponseView,
};

class RfqTradingView extends BaseView {
	constructor(options) {
		super(options);

		// initiate models
		// model for a quote
		this.quoteModel = new RfqTradingQuoteModel();

		// model for trade on quote (for execution at target price)
		this.quoteTradeModel = new QuoteTradeModel();

		this.listenTo(Eventbus, 'form:submit', this.onQuoteFormSubmit);

		this.hasQuote = false;

		// a quote / execution at target price can be returned instantly
		// after the request or after a wait of x seconds.
		// In that last case, a wait view is displayed until a signalR
		// event is caught.
		this.listenTo(Eventbus, SocketEvents.QUOTE, this.onQuoteReceived);
		this.listenTo(Eventbus, SocketEvents.TRADE, this.onTradeReceived);

		// define subviews
		this.views = { form: new RfqTradingFormView({ el: '.js-form' }) };

		this.setupSocket();
	}

	ui() {
		return {
			toggleMifid2Fields: '.js-toggle-mifid2Fields',
			mifid2Fields: '#mifid2Fields',
		};
	}

	events() {
		return {
			'change @ui.toggleMifid2Fields': 'onToggleMifid2Fields',
		};
	}

	setupSocket() {
		// create socket
		if (window.config && window.config.signalR) {
			this.socket = new SocketClient(window.config.signalR);
		}
	}

	onToggleMifid2Fields() {
		const [el] = this.uiElements.toggleMifid2Fields;
		const selectedOption = el.options[el.selectedIndex];
		const showPanel = selectedOption.getAttribute('data-show-mifid-fields') === 'true';

		this.toggleMifid2Fields(showPanel);
	}

	toggleMifid2Fields(showPanel) {
		const [panel] = this.uiElements.mifid2Fields;

		if (showPanel) {
			panel.classList.remove('is-hidden');
		} else {
			panel.classList.add('is-hidden');
		}
	}

	// handles all the form submits during RFQ trading
	onQuoteFormSubmit(form) {
		const { action } = form;
		const { method } = form;
		const data = DOM.serializeForm(form);

		this.handleAction(action, method, data).then(
			this.onRequestSuccess.bind(this),
			this.onRequestError.bind(this),
		);
	}

	// do a ajax request with form action, method and data
	handleAction(url, type, data) {
		return Backbone.ajax({
			url,
			type,
			data,
		});
	}

	// handle successful ajax request
	onRequestSuccess(response, status, xhr) {
		// get the custom response header from the request
		const responseAction = xhr.getResponseHeader('Cats-Response-Action');

		if (responseAction === 'error') {
			this.showError(response);
		} else {
			this.renderOutputView(responseAction, response);
		}
	}

	onRequestError(error) {
		// show modal with error message
		Eventbus.trigger('modal:show:html', error.responseText);
	}

	onQuoteReceived(quote) {
		this.hasQuote = true;

		if (quote.rejected) {
			const html = this.views.form.renderErrorTooltip(quote);

			this.showError(html);

			return;
		}

		this.quoteModel.set(quote);

		const quoteView = new RfqTradingResponseDetailView({ model: this.quoteModel });
		const modalHasEventListeners = true;
		Eventbus.trigger('modal:show:view', quoteView.render(), modalHasEventListeners);
	}

	onTradeReceived(trade) {
		if (trade.rejected) {
			const html = this.views.form.renderErrorTooltip(trade);

			this.showError(html);

			return;
		}

		this.quoteTradeModel.set(trade);

		const quoteTradeView = new RfqTradingResponseTradeView({ model: this.quoteTradeModel });

		Eventbus.trigger('modal:show:view', quoteTradeView.render());
	}

	showError(errorHtml) {
		Eventbus.trigger('modal:hide');

		this.views.form.showErrorTooltip(errorHtml);
	}

	// render a view depending on the custom response header from the server
	// the response is the complete HTML for the page
	renderOutputView(responseAction, html) {
		if (this.hasQuote && responseAction === 'wait') {
			this.hasQuote = false;

			return;
		}

		const View = RESPONSE_VIEWS[responseAction || 'default'];

		if (!View) {
			throw new Error(`no view found for responseAction: ${responseAction}`);
		}

		const modalHasEventListeners = true;
		Eventbus.trigger('modal:show:view', new View().render(html), modalHasEventListeners);
	}
}

export default RfqTradingView;
