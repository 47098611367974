import BaseView from '../BaseView';

const noop = () => {};

// Progress bar
// Make it decrease from 100 to 0 in x MS
// --------------------------------------
class RfqTradingResponseView extends BaseView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.tagName = 'progress';
		// eslint-disable-next-line no-param-reassign
		options.className = 'progress js-progress';
		// eslint-disable-next-line no-param-reassign
		options.attributes = {
			value: 100,
			max: 100,
		};

		super(options);

		this.onComplete = options.onComplete || noop;

		// total time to decrease 100 to 0
		this.runTime = options.time;

		// begin value
		this.value = 100;

		// keep track of state
		this.isRunning = false;

		this.timeout = null;

		// use an interval of 150MS
		// IE doesn't render the update of a
		// progress element with a higher interval
		this.interval = 150;
		this.valueDecrease = (100 / this.runTime) * this.interval;
	}

	start() {
		if (this.isRunning) {
			return this;
		}

		// reset value
		this.startTime = Date.now();
		this.isRunning = true;
		this.value = 100;

		this.tick();

		return this;
	}

	tick() {
		this.timeout = setTimeout(() => this.update(), this.interval);
	}

	stop() {
		this.isRunning = false;
		clearTimeout(this.timeout);

		return this;
	}

	complete() {
		this.endTime = Date.now();

		// total time running in MS
		const totalTime = this.endTime - this.startTime;

		// clear timeout
		this.stop();

		this.onComplete(totalTime);

		return this;
	}

	// 'private' methods
	update() {
		this.value -= this.valueDecrease;

		this.updateProgress(this.el, this.value);

		if (this.value > 0) {
			this.tick();
		} else {
			this.complete();
		}
	}

	updateProgress(el, value) {
		// eslint-disable-next-line no-param-reassign
		el.value = value;
	}
}

export default RfqTradingResponseView;
