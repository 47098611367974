import Backbone from 'backbone';
import _ from 'underscore';
import * as DOM from '../../utils/dom';
import Eventbus from '../../utils/eventbus';

import LimitTradingFormModel from '../../models/LimitTradingFormModel';

import DatepickerView from '../shared/DatepickerView';
import AutoCompleteView from '../shared/autocomplete';
import ValidationFormView from '../shared/ValidationFormView';

import LimitTradingResponseView from './LimitTradingResponseView';
import conditionalFields from './conditionalFields';

class LimitTradingView extends ValidationFormView {
	ui() {
		return _.extend({}, super.ui(), {
			referencePrice: '.js-reference-price',
			toggleMifid2Fields: '.js-toggle-mifid2Fields',
			btnRefPrice: '.js-button-ref-price',
			btnCloseRefPrice: '.js-button-ref-price-close',
			inputLimitPrice: 'input[name=price]',
			inputBrokerId: 'select[name=brokerId]',
			inputMarketmakerId: 'select[name=marketmakerId]',
			inputVolume: 'input[name=volume]',
			inputStopPrice: 'input[name=stopPrice]',
			inputCustomerReference: 'input[name=customerReference]',
			inputOrderType: 'select[name=orderType]',
			inputOrderValidity: 'radio[name=orderValidity]',
			inputMarketMaker: 'select[name=marketmakerId]',

			currencyContainer: '.js-currency-container',
			tooltipContainer: '.js-tooltip-container',
			mifid2Fields: '#mifid2Fields',
		});
	}

	events() {
		return _.extend({}, super.events(), {
			'change .js-validate.js-date-selector': 'onValidationFieldChange',
			'change @ui.inputMarketMaker': 'onMarketMakerChange',
			'click @ui.btnRefPrice': 'onRefPriceClick',
			'click @ui.btnCloseRefPrice': 'onCloseRefPriceClick',
			'input @ui.inputCustomerReference': 'onCustomerReferenceInput',
			'change @ui.toggleMifid2Fields': 'onToggleMifid2Fields',
		});
	}

	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new LimitTradingFormModel();

		super(options);

		this.listenTo(this.model, 'change:instrumentIdentifier', this.onInstrumentIdentifierChange);
		this.listenTo(this.model, 'change:orderType', this.onOrderTypeChange);
		this.listenTo(this.model, 'change:orderValidity', this.onOrderValidityChange);

		// this event can be dispatched from views rendered in the modal window
		this.listenTo(Eventbus, 'form:submit', this.submitForm);
		this.listenTo(Eventbus, 'form:confirm:cancel', this.onConfirmCancel);

		// define subviews
		this.views = {
			autoComplete: new AutoCompleteView({ el: this.el, model: this.model }),
		};
	}

	clearAutocompleteDescription() {
		const [description] = this.uiElements.autocompleteDescription;

		description.textContent = description.dataset.labelEmpty;
	}

	onToggleMifid2Fields() {
		const [el] = this.uiElements.toggleMifid2Fields;
		const selectedOption = el.options[el.selectedIndex];
		const showPanel = selectedOption.getAttribute('data-show-mifid-fields') === 'true';

		this.toggleMifid2Fields(showPanel);
	}

	createDatePicker() {
		const minDate = new Date();
		const maxDate = new Date();
		const disableWeekends = false;
		const datepickerEl = this.el.querySelector('.js-expiredate');

		// depending on the user, the element may or may be not present
		if (!datepickerEl) {
			return;
		}

		maxDate.setDate(maxDate.getDate() + 365);

		this.datePicker = new DatepickerView({
			el: datepickerEl,
			picker: {
				minDate,
				maxDate,
				disableWeekends,
			},
		}).render();
	}

	toggleMifid2Fields(showPanel) {
		const [panel] = this.uiElements.mifid2Fields;

		if (showPanel) {
			panel.classList.remove('is-hidden');
		} else {
			panel.classList.add('is-hidden');
		}
	}

	// toggle form fields based on the selected order type
	onOrderTypeChange(model, orderType) {
		conditionalFields.setOrderType(orderType);

		// changing order type can change the value of buy
		// get it, and update the model
		this.model.set('buy', this.el.querySelector('input[name=buy]:checked').value);
	}

	// toggle form fields based on selected order validity
	onOrderValidityChange(model, orderValidity) {
		conditionalFields.setOrderValidity(orderValidity);
	}

	onMarketMakerChange() {
		this.toggleCurrencyDropdown();
	}

	// get a reference price
	onRefPriceClick(e) {
		// check if a valid identfier is provided
		const instrumentIdentifier = this.getField('instrumentIdentifier');
		const marketMakerId = this.getField('marketmakerId');
		const validIdentfier = this.validateField(instrumentIdentifier, true);
		const validMarketMaker = this.validateField(marketMakerId, true);
		const action = e.target.href;

		e.preventDefault();

		this.hideReferencePrice();

		if (!validIdentfier || !validMarketMaker) {
			return;
		}

		// request the reference price by ajax
		this.handleAction(action, 'get', {
			instrumentIdentifier: instrumentIdentifier.value,
			marketmakerId: this.model.get('marketmakerId'),
		}).then(
			this.onRequestSuccess.bind(this),
			this.onRequestError.bind(this),
		);
	}

	onCloseRefPriceClick(e) {
		e.preventDefault();

		this.hideReferencePrice();
	}

	// transform value to uppercase
	onCustomerReferenceInput(e) {
		const field = e.target;

		field.value = field.value.toUpperCase();
	}

	// toggle fields based on selected order type
	setConditionalFields() {
		// depending on the user, field `orderType` may, or may not be present
		const [orderTypeField] = this.uiElements.inputOrderType;

		if (!orderTypeField) {
			return;
		}

		const orderType = this.uiElements.inputOrderType[0].value;

		conditionalFields.setOrderType(orderType);
	}

	// toggle the currency dropdown based on the selected market maker
	toggleCurrencyDropdown() {
		const [marketMakerDropdown] = this.uiElements.inputMarketMaker;
		const [currencyContainer] = this.uiElements.currencyContainer;

		if (!marketMakerDropdown || !currencyContainer) {
			return;
		}

		const selectedOption = marketMakerDropdown.options[marketMakerDropdown.selectedIndex];
		const hasCurrency = selectedOption.getAttribute('data-currency-selector') !== null;

		if (hasCurrency) {
			currencyContainer.classList.remove('is-hidden');
		} else {
			currencyContainer.classList.add('is-hidden');
		}
	}

	// base override
	onFormSubmit(e) {
		const invalidAttributes = this.validate(true);
		const form = e.target;

		e.preventDefault();

		this.hideReferencePrice();

		if (invalidAttributes.length === 0) {
			this.submitForm(form);
		}
	}

	// base override
	onFormReset() {
		// give the browser time to reset form before accessing form values again
		setTimeout(() => {
			this.resetForm();
			this.toggleMifid2Fields(false);
		}, 50);
	}

	// base override
	resetForm() {
		const defaults = this.model.defaults();

		super.hideValidationFeedback();

		this.model.set(defaults);

		// (re)set conditional fields
		this.setConditionalFields();

		// empty reference price
		this.uiElements.referencePrice[0].innerHTML = '';
	}

	// cancelling the confirm form
	onConfirmCancel() {
		// reset fields
		const defaults = this.model.defaults();

		this.uiElements.inputVolume[0].value = defaults.volume;
		this.uiElements.inputStopPrice[0].value = defaults.stopPrice;
		this.uiElements.inputLimitPrice[0].value = defaults.price;

		// empty reference price
		this.uiElements.referencePrice[0].innerHTML = '';

		// reset datepicker to default date
		this.datePicker.reset();

		// reset model
		this.model.set({
			volume: defaults.volume,
			stopPrice: defaults.stopPrice,
			price: defaults.price,
		});
	}

	// do a ajax request with form action, method and data
	handleAction(url, type, data) {
		return Backbone.ajax({
			url,
			type,
			data,
			context: this,
		});
	}

	// handle successful ajax request
	onRequestSuccess(response, status, xhr) {
		// get the custom response header from the request
		const responseAction = xhr.getResponseHeader('Cats-Response-Action');

		this.renderOutputView(response, responseAction);
	}

	onRequestError(error) {
		this.renderOutputView(error.responseText, 'error');
	}

	// render a view depending on the custom response header from the server
	renderOutputView(html, responseAction = 'error') {
		// no need for response / modal view
		// render it in the DOM
		if (responseAction === 'referenceprice') {
			this.showReferencePrice(html);

			return;
		}

		if (responseAction === 'cancelled' || responseAction === 'error') {
			Eventbus.trigger('modal:hide');

			this.showErrorTooltip(html);

			return;
		}

		const modalHasEventListeners = true;
		Eventbus.trigger('modal:show:view', new LimitTradingResponseView().render(html), modalHasEventListeners);
	}

	showReferencePrice(html) {
		this.uiElements.referencePrice[0].innerHTML = html;
	}

	hideReferencePrice() {
		this.uiElements.referencePrice[0].innerHTML = '';
	}

	// called from Ajax Error call
	showErrorTooltip(html) {
		Eventbus.trigger('modal:hide');
		this.uiElements.tooltipContainer[0].innerHTML = html;
	}

	hideErrorTooltip() {
		this.uiElements.tooltipContainer[0].innerHTML = '';
	}

	// submit form through ajax
	submitForm(form) {
		const { action, method } = form;
		const data = DOM.serializeForm(form);

		this.handleAction(action, method, data).then(
			this.onRequestSuccess.bind(this),
			this.onRequestError.bind(this),
		);

		this.hideErrorTooltip();
	}

	onAfterRender() {
		conditionalFields.init(this.el);

		this.setConditionalFields();
		this.toggleCurrencyDropdown();

		this.createDatePicker();
	}

	onBeforeRemove() {
		this.datePicker.remove();
	}
}

export default LimitTradingView;
