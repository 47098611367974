import _ from 'underscore';
import ValidationModel from './ValidationModel';

const IP_REGEX = /^(([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)\.){3}([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)$/; // eslint-disable-line max-len
const TELEPHONE_REGEX = /^\+?\d+$/;

class AccountModel extends ValidationModel {
	defaults() {
		return {
			allowedIp: '',
			firstName: '',
			lastName: '',
			email: '',
			phoneMobile: '',
		};
	}

	validation() {
		return _.extend({}, super.validation(), {
			firstName: 'isNotEmpty',
			lastName: 'isNotEmpty',
			email: 'email',

			phoneMobile(value) {
				return TELEPHONE_REGEX.test(value);
			},

			// an IP field can be empty, but if filled, it has to be a valid IP
			allowedIp(value, model) {
				return model.helpers().isEmpty(value) || IP_REGEX.test(value);
			},
		});
	}

	// properties in this model are fieldId's in the DOM
	// When a new field is added, also add his validation rule
	addIpValidation(fieldId) {
		const currentValidation = this.validation();

		currentValidation[fieldId] = currentValidation.allowedIp;

		this.validation = () => currentValidation;
	}

	// remove validation when DOM element is removed
	removeIpValidation(fieldId) {
		const currentValidation = this.validation();

		delete currentValidation[fieldId];

		this.validation = () => currentValidation;
	}
}

export default AccountModel;
