import Eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';
import RfqTradingQuoteModel from '../../models/RfqTradingQuoteModel';

class RfqTradingResponseView extends BaseView {
	events() {
		return {
			'submit form': 'onFormSubmit',
		};
	}

	initialize() {
		this.model = this.model || new RfqTradingQuoteModel();
	}

	onFormSubmit(e) {
		e.preventDefault();

		Eventbus.trigger('form:submit', e.target);
	}

	render(html) {
		const data = { ...window.config.preferences, ...this.model.toJSON() };

		if (this.template) {
			// eslint-disable-next-line no-param-reassign
			html = this.template.render(data);
		}

		this.el.innerHTML = html;

		super.render();

		return this;
	}
}

export default RfqTradingResponseView;
