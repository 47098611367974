import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const dateFormatted = moment().format('DD-MM-YYYY');

class StatisticFormModel extends SearchFormModel {
	defaults() {
		return {
			// TODO: this and folling date/time props are already set on the super class!
			dateFrom: dateFormatted,
			dateTo: dateFormatted,
			timeFrom: '00:00:00',
			timeTo: '00:00:00',
			StatisticsSubjects: null,
			brokerIds: null,
			marketMakerIds: null,
			GroupByColumns: null,
		};
	}
}

export default StatisticFormModel;
