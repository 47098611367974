import _ from 'underscore';

import Backbone from 'backbone';
import Eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';
import template from '../../../../../Views/Shared/Partials/_Modal.mustache';

class ModalView extends BaseView {
	ui() {
		return {
			btnClose: '.js-button-close',
			contentHolder: '.js-modal-content',
		};
	}

	determineModalSize() {
		let modalSize = '';
		if (this.el.getElementsByClassName('table-container').length > 0) modalSize = 'stretched';

		return modalSize;
	}

	events() {
		return { 'click @ui.btnClose': 'onBtnCloseClick' };
	}

	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.className = 'modal is-hidden';

		super(options);

		this.listenTo(Eventbus, 'modal:hide', this.hide);

		_.bindAll(this, 'onKeyDown');
	}

	loadAndShow(options, modalHasEventListeners = false) {
		// eslint-disable-next-line no-param-reassign
		options.ajax = options.ajax || {};

		_.defaults(options.ajax, {
			type: 'GET',
			dataType: 'html',
			cache: false,
			context: this,
		});

		this.load(options.ajax)
			.then((html) => this.showHtml(html, modalHasEventListeners));
	}

	load(options) {
		return Backbone
			.ajax(options)
			.fail((error) => {
				// user is not logged in
				if (error.status === 401) {
					window.location = `/authentication?redirectUrl=${encodeURIComponent(options.url)}`;

					return;
				}

				this.showHtml(error.responseText);
			});
	}

	showView(view, modalHasEventListeners) {
		// make sure previous rendered views are removed
		this.empty();

		this.render()
			.show()
			.setHtml(view.el, modalHasEventListeners);

		this.modalView = view;
		this.listenToOnce(this.modalView, 'remove', this.onModalViewRemove);
	}

	showHtml(html, modalHasEventListeners) {
		this.render()
			.show()
			.setHtml(html, modalHasEventListeners);
	}

	setHtml(element, modalHasEventListeners) {
		if (modalHasEventListeners) {
			this.uiElements.contentHolder[0].appendChild(element);
		} else {
			this.uiElements.contentHolder[0].innerHTML = element;
		}

		const modalCSSModifier = this.determineModalSize();
		// add size modifier class
		if (modalCSSModifier) {
			this.el.classList.add(`modal--${modalCSSModifier}`);
		}
	}

	onModalViewRemove() {
		this.modalView = null;

		this.hide(true);
	}

	onBtnCloseClick(e) {
		e.preventDefault();

		this.hide();
	}

	hide() {
		this.empty();

		this.el.classList.add('is-hidden');

		document.documentElement.classList.remove('modal-is-active');

		document.removeEventListener('keydown', this.onKeyDown);

		Eventbus.trigger('modal:hidden', this);
	}

	show() {
		this.el.className = 'modal is-hidden';
		this.el.classList.remove('is-hidden');

		document.documentElement.classList.add('modal-is-active');

		document.addEventListener('keydown', this.onKeyDown);

		return this;
	}

	empty() {
		if (this.modalView) {
			this.modalView.remove();
			this.modalView = null;
		}

		this.content = '';
		this.uiElements.contentHolder[0].innerHTML = '';
	}

	onKeyDown(e) {
		// 27: escape
		if (e.which === 27) {
			this.hide();
		}
	}

	// base override
	render() {
		const data = { content: '' };

		this.el.innerHTML = template.render(data);

		return super.render();
	}
}

export default ModalView;
