// import Tablesort from 'tablesort';
// import 'tablesort/src/sorts/tablesort.number';

const ATTR_SORT_DIRECTION = 'data-sort-direction';

class Table {
	constructor(el) {
		this.el = el;
	}

	start() {
		// this.sorter = new Tablesort(this.el);
		this.body = this.el.querySelector('tbody');
		this.headersAll = Array.from(this.el.querySelectorAll('thead th:not([data-sort-ignore])'));
		this.headersSortable = Array.from(this.el.querySelectorAll('thead th.is-sortable'));
		this.rows = Array.from(this.el.querySelectorAll('tbody tr'));

		this.headersSortable.forEach((header) => {
			header.addEventListener('click', (e) => this.onSortHeaderClick(e));
		});
	}

	onSortHeaderClick(e) {
		const header = e.target;
		const sortMethod = header.getAttribute('data-sort-method') || '';
		const isNummeric = sortMethod === 'number' || false;
		const currentDirection = header.getAttribute(ATTR_SORT_DIRECTION);
		const direction = currentDirection === 'desc' ? -1 : 1;

		this.sortTable(header, isNummeric, direction);
		this.updateHeaders(header, direction);
	}

	/**
	 * Sort rows of the Table
	 * @param {Bool} sortNummeric - use nummeric sorting
	 * @param {Int} direction - 1 or -1
	 */
	sortTable(header, sortNummeric, direction) {
		const headerIndex = this.headersAll.indexOf(header);

		if (headerIndex === -1) {
			return;
		}

		const frag = document.createDocumentFragment();

		this.rows
			.sort((a, b) => {
				const cellA = a.children[headerIndex];
				const cellB = b.children[headerIndex];

				let valueA = Table.getCellValue(cellA);
				let valueB = Table.getCellValue(cellB);

				if (!(valueA && valueB)) {
					return 0;
				}

				if (sortNummeric) {
					valueA = parseFloat(valueA);
					valueB = parseFloat(valueB);
				} else {
					valueA = valueA.toLowerCase();
					valueB = valueB.toLowerCase();
				}

				if (valueA < valueB) {
					return -direction;
				}

				if (valueA > valueB) {
					return direction;
				}

				return 0;
			})
			.forEach((row) => {
				frag.appendChild(row);
			});

		this.body.appendChild(frag);
	}

	/**
	 * Update headers after sorting
	 * @param {HTMLElement} headerSorted - the header on which was sorted
	 * @param {Int} sortedDirection - direction table was sorted on. -1 or 1
	 */
	updateHeaders(headerSorted, sortedDirection) {
		const directionAttrValue = sortedDirection === 1 ? 'desc' : 'asc';
		const directionClass = sortedDirection === 1 ? 'sort-up' : 'sort-down';

		this.headersSortable.forEach((header) => {
			// http://caniuse.com/#feat=classlist
			header.classList.remove('is-sorted');
			header.classList.remove('sort-down');
			header.classList.remove('sort-up');

			if (header === headerSorted) {
				header.setAttribute(ATTR_SORT_DIRECTION, directionAttrValue);
				header.classList.add(directionClass, 'is-sorted');
			} else {
				header.removeAttribute(ATTR_SORT_DIRECTION);
			}
		});
	}

	/**
	 * Returns the value of a cell to sort on
	 * @param {HTMLElement} cell - the cell to get the value from
	*/
	static getCellValue(cell) {
		const val = cell.getAttribute('data-sort') || cell.innerText || '';

		return val;
	}
}

export default Table;
