import { Model } from 'backbone';

class ProductModel extends Model {
	defaults() {
		return {
			instrumentIdentifier: '',
			marketMakerId: '',
			volume: null,
		};
	}
}

export default ProductModel;
