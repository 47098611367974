import _ from 'underscore';
import ValidationModel from './ValidationModel';

class AccountSettingsModel extends ValidationModel {
	defaults() {
		return {
			preTradeConfirmation: false,
			tradeConfirmationAmount: '',
		};
	}

	validation() {
		return _.extend({}, super.validation(), {

			// can be empty
			// if not empty, it has to be a positive number
			tradeConfirmationAmount(value, model) {
				// array of values since it's bound to a checkbox
				const preTradeConfirmation = model.get('preTradeConfirmation');

				if (!preTradeConfirmation.length) {
					return true;
				}

				return this.helpers().isEmpty(value)
					|| (this.helpers().isPositiveNumber(value) && this.helpers().isDigit(value));
			},
		});
	}
}

export default AccountSettingsModel;
