import _ from 'underscore';
import moment from 'moment';
import ValidationModel from './ValidationModel';

const dateFormatted = moment().format('DD-MM-YYYY');

class SearchFormModel extends ValidationModel {
	// default attribute values
	defaults() {
		return {
			dateFrom: dateFormatted,
			dateTo: dateFormatted,
			instrumentIdentifier: null,
			timeFrom: '00:00:00',
			timeTo: '23:59:59',
		};
	}

	// extend base helpers
	helpers() {
		return _.extend({}, super.helpers(), {
			isValidDate(value) {
				const parts = value.split('-');
				const dateTo = new Date(parts[2], parts[1] - 1, parts[0]);
				const dateNow = new Date();

				// align times
				dateTo.setHours(0, 0, 0);
				dateNow.setHours(0, 0, 0);

				return dateTo <= dateNow;
			},

			isValidTime(value, model) {
				return model.helpers().isEmpty(value)
					|| model.patterns().timeNonSeparated.test(value)
					|| model.patterns().timeSeparated.test(value);
			},
		});
	}

	// attributes to validate and their rules
	validation() {
		return {
			// dateFrom can't be empty
			// has to be a valid date (dd-mm-yyyy)
			// can't be in the future, max today
			dateFrom(value) {
				const validPattern = this.patterns().date.test(value);

				if (!validPattern) {
					return false;
				}

				return this.helpers().isValidDate(value);
			},

			// dateTo can't be empty
			// has to be a valid date (dd-mm-yyyy)
			// can't be in the future, max today
			dateTo(value) {
				const validPattern = this.patterns().date.test(value);

				if (!validPattern) {
					return false;
				}

				return this.helpers().isValidDate(value);
			},

			// execution price can be empty
			// if not, must be number and higher than zero
			executionPrice(value) {
				if (this.helpers().isNotEmpty(value)
					&& (!this.helpers().isNumeric(value)
					|| !this.helpers().isPositiveNumber(value))) {
					return false;
				}

				return true;
			},

			// instrument indentifier can be empty
			// if not, it must be a ISIN or WKN
			instrumentIdentifier(value) {
				const isIsin = this.patterns().isin.test(value);
				const isWkn = this.patterns().wkn.test(value);

				if (this.helpers().isNotEmpty(value) && (!isIsin && !isWkn)) {
					return false;
				}

				return true;
			},

			timeFrom: 'isValidTime',

			timeTo: 'isValidTime',

			// quantity can be empty
			// but if not, it should be a positive number
			quantity(value) {
				if (this.helpers().isNotEmpty(value)
					&& (
						!this.helpers().isDigit(value)
						|| !this.helpers().isPositiveNumber(value))
				) {
					return false;
				}

				return true;
			},
		};
	}

	initialize() {
		super.initialize();
	}
}

export default SearchFormModel;
