import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const defaultDate = moment().format('DD-MM-YYYY');

class OrderFormModel extends SearchFormModel {
	defaults() {
		return {
			brokerId: null,
			catsEndUserId: '',
			catsOrderId: null,
			customerReference: '',
			dateFrom: defaultDate,
			dateTo: defaultDate,
			instrumentIdentifier: null,
			marketmakerId: null,
			orderStatus: null,
			orderType: null,
			quantity: null,
			resultsPerPage: 25,
			orderDateType: '',
			timeFrom: '00:00:00',
			timeTo: '23:59:59',
			page: 1,
		};
	}
}

export default OrderFormModel;
