/* eslint-disable line-comment-position */

// Fields in the limit trading form should be toggled depending on the selected order type
// The selected order type is represented by an integer
// By passing the selected order type to `setOrderType`, the appropiate fields are toggled
//
// NOTE - TODO:
// if the value of order types are changed in the DOM (eg. to strings),
//  the `orderTypes` object has to be changed as well

const conditionalFields = {
	form: null,
	fields: {
		buy: '.js-side-buy',
		delta: '.js-delta',
		limitPrice: '.js-limit-price',
		sell: '.js-side-sell',
		stopTrigger: '.js-stop-trigger-price',
		expireDates: '.js-expire-dates',
		fillKill: '.js-fill-kill',
		validityDate: '.js-order-validity-date',
	},

	fieldsByOrderType: [
		'buy',
		'delta',
		'limitPrice',
		'sell',
		'stopTrigger',
		'expireDates',
		'fillKill',
	],

	fieldsByOrderValidity: ['expireDates'],

	// key: the order type
	// value: the fields from the `fields` object which should be hidden
	orderTypes: {
		0: ['sell', 'delta', 'fillKill'],					// buy in limit, buy should be checked
		1: ['sell', 'limitPrice', 'delta', 'fillKill'],		// buy in market, buy should be checked
		2: ['stopTrigger', 'delta'],						// limit
		3: ['limitPrice', 'stopTrigger', 'delta'],			// market
		4: ['delta', 'fillKill'],							// One-Cancels-The-Other
		5: ['buy', 'delta', 'fillKill'],					// Stop loss limit, sell should be checked
		6: ['buy', 'limitPrice', 'delta', 'fillKill'],		// Stop loss market, sell should be checked
		7: ['limitPrice', 'fillKill'],						// Trailing SL/BI order
	},

	// key: the order validity
	// value: the field from the `fields` object which should be hidden
	orderValidities: {
		0: [],
		1: ['expireDates'],
		2: ['expireDates'],
	},

	init(formEl) {
		this.form = formEl;
		this.setFields();
	},

	setFields() {
		// get the DOM element by selector
		Object.keys(this.fields).forEach((key) => {
			this.fields[key] = this.form.querySelector(this.fields[key]);
		});
	},

	setOrderType(orderType) {
		const id = parseInt(orderType, 10);
		const hideFields = this.orderTypes[id];

		// first, show all fields, excluding the ones that should be hidden
		this.show(this.fieldsByOrderType, hideFields);

		// hide the ones that should be hidden
		hideFields.forEach((fieldName) => {
			if (this.fields[fieldName]) {
				this.fields[fieldName].classList.add('is-hidden');
			}
		});

		// select the right radio button(s)
		if (id === 0 || id === 1) {
			// buy in limit, buy in market
			this.fields.buy.checked = true;
		}

		if (id === 5 || id === 6) {
			// Stop loss limit, Stop loss market
			this.fields.sell.checked = true;
		}

		if (id !== 2 && id !== 3) {
			// All but Limit Order and Market Order
			this.fields.validityDate.checked = true;
		}
	},

	setOrderValidity(orderValidity) {
		const validity = parseInt(orderValidity, 10);
		const hideFields = this.orderValidities[validity];

		// first, show all fields, excluding the ones that should be hidden
		this.show(this.fieldsByOrderValidity, hideFields);

		// hide the ones that should be hidden
		hideFields.forEach((fieldName) => {
			if (this.fields[fieldName]) {
				this.fields[fieldName].classList.add('is-hidden');
			}
		}, this);
	},

	show(showFields, exclude) {
		const excludeFields = exclude || [];
		let fieldEl;

		showFields.forEach((fieldName) => {
			fieldEl = this.fields[fieldName];

			if (fieldEl && excludeFields.indexOf(fieldName) === -1) {
				fieldEl.classList.remove('is-hidden');
			}
		});
	},
};

export default conditionalFields;
