import DashboardVisualization from './modules/dashboard-visualization';
import Expandable from './modules/expandable';
import PanelToggle from './modules/panelToggle';
import Table from './modules/stats/table';

export default {
	'dashboard-visualization': DashboardVisualization,
	'stats/table': Table,
	expandable: Expandable,
	panelToggle: PanelToggle,
};
