import moment from 'moment';
import SearchFormModel from './SearchFormModel';

const dateFormatted = moment().format('DD-MM-YYYY');

class AlertFormModel extends SearchFormModel {
	defaults() {
		return {
			alertCode: '',
			brokerId: null,
			dateFrom: dateFormatted,
			dateTo: dateFormatted,
			marketmakerId: null,
			resultsPerPage: 25,
			timeFrom: '00:00:00',
			timeTo: '00:00:00',
			page: 1,
		};
	}
}

export default AlertFormModel;
