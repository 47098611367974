import Eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';
import RfqTradingTimerView from './RfqTradingTimerView';

// Wait view
// the user has to wait on the market maker for a quote
// he can choose to wait, which keeps the signalR subscription
// or not wait, which ends the subscription
// -----------------------------------------------------------
class RfqTradingWaitView extends BaseView {
	ui() {
		return {
			btnWait: '.js-button-wait',
			btnCancel: '.js-button-cancel',
		};
	}

	events() {
		return {
			'click @ui.btnWait': 'onWaitClick',
			'click @ui.btnCancel': 'onCancelClick',
			'submit form': 'onFormSubmit',
		};
	}

	constructor(options) {
		super(options);

		this.views = {
			timer: new RfqTradingTimerView({
				time: 45000,
				onComplete: this.onTimerComplete.bind(this),
			}),
		};
	}

	onWaitClick() {
		this.uiElements.btnWait[0].disabled = true;
		this.uiElements.btnCancel[0].disabled = true;
	}

	onCancelClick() {
		this.remove();
	}

	onTimerComplete() {
		this.remove();
	}

	onFormSubmit(e) {
		e.preventDefault();
		Eventbus.trigger('form:submit', e.target);

		this.remove();
	}

	render(html) {
		this.el.innerHTML = html;

		super.render();

		return this;
	}

	onAfterRender() {
		this.el.appendChild(this.views.timer.el);
		this.views.timer.start();
	}
}

export default RfqTradingWaitView;
