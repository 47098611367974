import SearchView from '../shared/search/SearchView';
import SearchResultsView from '../shared/search/SearchResultsView';
import StatisticsFormView from './StatisticsFormView';

class StatisticsView extends SearchView {
	// override initialize
	constructor(options = {}) {
		// define subviews to be rendered by BaseView
		// in the `onRender` method
		// eslint-disable-next-line no-param-reassign
		options.views = {
			form: new StatisticsFormView({ el: '.js-filters' }),
		};

		// without results, there is no table
		if (document.querySelector('.js-table')) {
			// eslint-disable-next-line no-param-reassign
			options.views.result = new SearchResultsView({ el: '.js-table' });
		}

		super(options);
	}
}

export default StatisticsView;
