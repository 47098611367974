import Chart from 'chart.js';
import objectMerge from 'lodash/merge';

import ChartVisualizationModel from '../models/chart-visualization';
import VisualizationModel from '../models/visualization';

import chartSettings from '../config/chart';

export default {
	createChartVisualization(options = {}, classes = []) {
		// Fix for IE11 chart size
		// https://github.com/chartjs/Chart.js/issues/2422
		const canvasContainer = document.createElement('div');

		const canvas = document.createElement('canvas');
		const element = document.createElement('div');
		const chart = new Chart(canvas, objectMerge({
			options: {
				maintainAspectRatio: false,
			},
		}, options));

		element.classList.add('chart', ...classes);
		canvasContainer.classList.add('chart-wrapper');
		// element.appendChild(canvas);
		element.appendChild(canvasContainer);
		canvasContainer.appendChild(canvas);

		return new ChartVisualizationModel(element, chart);
	},

	createDistributionChartVisualization({
		title,
		labels,
		datasets,
		showLegend = false,
		tooltips = {},
	}, classes = []) {
		return this.createChartVisualization({
			type: 'bar',
			data: {
				labels,
				datasets: datasets.map((data, index) => {
					const colorIndex = index % chartSettings.colors.length;

					return Array.isArray(data)
						? {
							backgroundColor: chartSettings.colors[colorIndex],
							data,
						}
						: {
							backgroundColor: chartSettings.colors[colorIndex],
							...data,
						};
				}),
			},
			options: {
				legend: {
					display: showLegend,
				},
				title: {
					display: true,
					text: title,
				},
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
						},
					}],
				},
				tooltips,
			},
		}, classes);
	},

	createDefinitionListVisualization(list, { isVertical = false } = {}) {
		const modifiers = [];

		if (isVertical) {
			modifiers.push('definition-list--column definition-list--large');
		}

		return VisualizationModel.fromString(`<dl class="definition-list ${modifiers.join(' ')}">
			${list.map((listItem) => `<dt class="definition-list__term">${listItem.term}</dt>
			<dd class="definition-list__description">${listItem.description}</dd>`).join('')}
		</dl>`);
	},

	createPersonListVisualization(personList) {
		return personList.length
			? VisualizationModel.fromString(`<dl class="definition-list">
				${personList.map((person) => `<dt class="definition-list__term">${person.name}</dt>
				<dd class="definition-list__description">[${person.id}]</dd>`).join('')}
			</dl>`)
			: this.createEmptyState();
	},

	createEmptyState() {
		return VisualizationModel.fromString(`
			<div class="chart__empty-message">No data found for this category.</div>
		`);
	},
};
