import { Model } from 'backbone';

class RFQTradingQuoteModel extends Model {
	defaults() {
		return {
			amount: null,
			marketMakerId: '',
			brokerId: '',
			type: '',
			instrumentIdentifier: '',
			buyPrice: null,
			sellPrice: null,
			dealPrice: null,
			dealType: '',
			currency: '',
			volume: null,
			dateTime: '',
			customerReference: '',
			executionAtTargetPrice: false,
			executionBuySell: false,
			status: '',
			tokenHiddenField: null,
		};
	}
}

export default RFQTradingQuoteModel;
