import _ from 'underscore';
import Eventbus from '../../utils/eventbus';

import BaseView from '../BaseView';

// 1 minute in MS
const UPDATE_INTERVAL = 60 * 1000;

// 1 second in MS
const UPDATE_COUNTDOWN_INTERVAL = 1000;

const defaultConfig = {
	// time before session expires, in minutes
	sessionTimeout: 120,

	// time before notice will be displayed, in minutes
	minutesBeforeTimeoutForNoticeActivation: 10,
};

class InactiveNoticeView extends BaseView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.className = 'is-hidden global-warning';

		super(options);

		this.config = _.defaults(window.config || {}, defaultConfig);
		([this.countdownEl] = Array.from(this.el.querySelectorAll('.js-countdown')));
		([this.logoutButton] = Array.from(this.el.querySelectorAll('.js-logout')));

		this.listenTo(Eventbus, 'session:reset', this.onSessionTimeReset);

		this.start();
	}

	_convertSecondsToMinutesAndSeconds(secondsRemaining) {
		const minutes = Math.floor((secondsRemaining % 3600) / 60).toString().padStart(2, '0');
		const seconds = Math.floor(secondsRemaining % 60).toString().padStart(2, '0');

		return `${minutes}:${seconds}`;
	}

	start() {
		this.timerStarted = Date.now();

		this.modalIntervalId = setInterval(this.update.bind(this), UPDATE_INTERVAL);
	}

	update() {
		const now = Date.now();
		const minutesRunning = Math.floor((now - this.timerStarted) / 1000 / 60);
		const minutesLeft = this.config.sessionTimeout - minutesRunning;
		const noticeActive = minutesLeft <= this.config.minutesBeforeTimeoutForNoticeActivation;

		if (noticeActive && this.el.classList.contains('is-hidden')) {
			// display the modal
			this.display();

			// start the visual countdown
			this.startCountdown();
		}
	}

	display() {
		// hide modal windows if there is one
		Eventbus.trigger('modal:hide');

		// show the warning
		this.el.classList.remove('is-hidden');
	}

	startCountdown() {
		// prevent restart of the interval when its running
		if (this.startCountdownIntervalId) {
			return;
		}

		// Set initial message
		this.countdownEl.textContent = `Your session will expire in ${this.config.minutesBeforeTimeoutForNoticeActivation}:00 minutes.`;

		// start countdown interval
		this.countdownStartedTimestamp = Date.now();
		this.totalCountdownTimeInSeconds = this.config.minutesBeforeTimeoutForNoticeActivation * 60; // Convert to seconds

		// start countdown timer
		this.startCountdownIntervalId = setInterval(
			this.updateCountdown.bind(this),
			UPDATE_COUNTDOWN_INTERVAL,
		);
	}

	updateCountdown() {
		const now = Date.now();
		const secondsRunning = Math.floor((now - this.countdownStartedTimestamp) / 1000);
		const secondsRemaining = this.totalCountdownTimeInSeconds - secondsRunning;

		// if the timer is done
		if (secondsRemaining <= 0) {
			this.countdownEl.textContent = 'Your session is expired. Please extend your session.';

			clearInterval(this.startCountdownIntervalId);

			this.logoutButton.classList.add('is-hidden');
		} else {
			this.countdownEl.textContent = `Your session will expire in ${this._convertSecondsToMinutesAndSeconds(secondsRemaining)} minutes.`;
		}
	}

	onSessionTimeReset() {
		this.reset();
	}

	reset() {
		this.el.classList.add('is-hidden');
		this.logoutButton.classList.remove('is-hidden');

		clearInterval(this.modalIntervalId);
		clearInterval(this.startCountdownIntervalId);

		this.start();
	}
}

export default InactiveNoticeView;
