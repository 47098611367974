import _ from 'underscore';
import SearchFormView from '../shared/search/SearchFormView';
import StatisticFormModel from '../../models/StatisticFormModel';

class StatisticsFormView extends SearchFormView {
	constructor(options = {}) {
		// eslint-disable-next-line no-param-reassign
		options.model = new StatisticFormModel();

		super(options);
	}

	ui() {
		return _.extend({}, super.ui(), {
			subjects: '.js-subjects',
			groups: '.js-group-by',
		});
	}

	events() {
		return _.extend({}, super.events(), {
			'change @ui.subjects': 'onSubjectChange',
		});
	}

	get groupByOptions() {
		const ids = Array.from(this.uiElements.subjects)
			.filter((el) => el.checked)
			.map((el) => el.getAttribute('data-group-by-options').split(','));

		// Yeah, for underscore and the spread operator ;)
		return _.intersection(...ids);
	}

	onSubjectChange() {
		const options = this.groupByOptions;

		this.toggleGroupByOptions(options);
	}

	toggleGroupByOptions(options) {
		Array.from(this.uiElements.groups)
			.forEach((el) => {
				const isActive = options.indexOf(el.value) !== -1;

				// eslint-disable-next-line no-param-reassign
				el.checked = false;
				// eslint-disable-next-line no-param-reassign
				el.disabled = !isActive;
			});
	}
}

export default StatisticsFormView;
