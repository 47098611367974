const CLASS_IS_EXPANDED = 'is-expanded';

class Expandable {
	constructor(el) {
		this.el = el;

		this.container = this.el.querySelector('.js-container');
		this.toggle = this.el.querySelector('.js-button-toggle');
		this.isExpanded = false;
	}

	start() {
		if (this.toggle) {
			this.toggle.addEventListener('click', (e) => this.onToggleClicked(e));
		}
	}

	onToggleClicked(e) {
		e.preventDefault();

		this.isExpanded = !this.isExpanded;

		this.updateContainer();
		this.updateButton();
	}

	updateContainer() {
		this.container.classList.toggle(CLASS_IS_EXPANDED, this.isExpanded);
	}

	updateButton() {
		this.toggle.textContent = this.isExpanded
			? 'Show less'
			: 'Show more';
	}
}

export default Expandable;
