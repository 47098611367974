import _ from 'underscore';
import ValidationModel from './ValidationModel';

class RFQTradingFormModel extends ValidationModel {
	defaults() {
		return {
			brokerId: null,
			customerReference: '',
			currency: null,
			executionAtTargetPrice: false,
			instrumentIdentifier: null,
			marketmakerId: null,
			sell: null,
			targetPrice: null,
			volume: null,
			volumeConfirmed: false,
			volumeConfirmAmount: 0,
		};
	}

	validation() {
		return _.extend({}, super.validation(), {
			brokerId: 'isNotEmpty',

			customerReference: ['isNotEmpty', 'alphanumericSpace'],

			// can't be empty, must be 3 characters long
			currency: ['isNotEmpty', 'currency'],

			// can't be empty
			marketmakerId: 'isNotEmpty',

			// can't be empty, must be an ISIN or WKN
			instrumentIdentifier(value) {
				const isIsin = this.patterns().isin.test(value);
				const isWkn = this.patterns().wkn.test(value);

				return this.helpers().isNotEmpty(value) && (isIsin || isWkn);
			},

			executionAtTargetPrice() {
				return true;
			},

			// if execution at target price, targetPrice can't be empty
			// must be a positive number
			targetPrice(value, model) {
				const execAtTarget = model.get('executionAtTargetPrice');

				// execution at target price is not checked,
				// so a value isn't required
				if (execAtTarget === false || execAtTarget.length === 0) {
					return true;
				}

				return this.helpers().isNotEmpty(value) && this.helpers().isPositiveNumber(value);
			},

			// can't be empty, must be positive digit
			volume(value) {
				return this.helpers().isNotEmpty(value)
					&& this.helpers().isDigit(value)
					&& this.helpers().isPositiveNumber(value);
			},

			volumeConfirmed(value, model) {
				const volume = parseInt(model.get('volume') || 0, 10);
				const volumeConfirmAmount = parseInt(model.get('volumeConfirmAmount'), 10);

				// no need to confirm
				if (volumeConfirmAmount === 0 || volume < volumeConfirmAmount) {
					return true;
				}

				// the value is a checkbox so value is stored in an array
				return value === true || value[0] === 'true';
			},
		});
	}
}

export default RFQTradingFormModel;
