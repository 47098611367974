import { Model } from 'backbone';

class QuoteTradeModel extends Model {
	defaults() {
		return {
			amount: 0,
			currency: 'EUR',
			id: 0,
			price: 0,
			rejectReason: '',
			rejected: false,
			requestId: '',
			securityId: '',
			side: '',
			timestamp: '',
			volume: 0,
		};
	}
}

export default QuoteTradeModel;
